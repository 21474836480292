import { Link } from "react-router-dom";

export default function AlertPopup({ OpenNext, setOpenNext, AlertData }) {
  const handleCancel1 = () => {
    setOpenNext(false);
  };

  const handleDelete = () => {
    AlertData?.Alert();
    setOpenNext(false);
  };
  return (
    <>
      <div
        id="CreatContact"
        className={OpenNext ? "overlay-note w-100" : "overlay-note"}
      >
        <div
          style={{
            margin: "auto",
            marginTop: "10%",
          }}
          className="overlay-note-content mblVwe"
        >
          <div className="note-title" style={{ position: "relative" }}>
            <h4
              style={{ paddingLeft: "30px" }}
              className="text-left text-white pl-3"
            >
              Confirmation
            </h4>
            <div className="closebtn">
              <Link
                style={{ color: "white" }}
                id="hide-note-max"
                className="text-right text-white"
              >
                <i className="fas fa-minus"></i>
              </Link>
              <Link
                style={{ color: "white" }}
                className="text-right text-white"
                onClick={() => setOpenNext(false)}
              >
                <i className="fas fa-times"></i>
              </Link>
            </div>
          </div>
          <div
            style={{ padding: "20px" }}
            className="Send_Email_Form"
            id="noteBody"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div>
                {" "}
                <p>{AlertData?.msg} </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <button className="cst_btn1" onClick={() => handleDelete()}>
                  Yes
                </button>{" "}
                <button className="cst_btn2" onClick={() => handleCancel1()}>
                  No
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
