import { useLocation } from "react-router-dom";
import Footer from "../../Components/Footer";
import Navbar2 from "../../Components/Navbar2";
import Styles from "./Error.module.css";
import { BiError } from "react-icons/bi";
export default function ErrorPage() {
  const { state } = useLocation();
  return (
    <>
      <Navbar2 />
      <section className={Styles.Error_Container}>
        <div>
          <span
            style={{
              fontSize: "30px",
              color: "red",
              margin: "0",
              padding: "0",
            }}
          >
            <BiError />
          </span>
        </div>
        <h4>
          {state?.error || window.Error
            ? state?.error || window.Error
            : "Page Not Found !"}
        </h4>
        <div>
          <button className={Styles.custom_btn}>Go Back</button>
        </div>
      </section>
      <Footer />
    </>
  );
}
