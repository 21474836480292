import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import TopFooter from "../../Components/TopFooterSection";
import Footer from "../../Components/Footer";
import Styles from "./Fotgot.module.css";
import { Link, useNavigate } from "react-router-dom";
import { PasswordRest_Model } from "../../Model/UserModel";
import { useDispatch, useSelector } from "react-redux";
import { USER_FORGOT_PASSWORD } from "../../Action/UserAction";
import Loading from "../../Components/Loading";
import { USER_RESET_PASSWORD_RESET } from "../../Constant/UserConstant";
import Navbar2 from "../../Components/Navbar2";
const PasswordForgot = () => {
  const [LoginForm, setLoginForm] = useState(PasswordRest_Model);

  const { loading, success, error } = useSelector(
    (state) => state.USER_FORGOT_PASSWORD_REDUCER
  );

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const handleChange = (e) => {
    setLoginForm({ ...LoginForm, [e.target.name]: e.target.value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(USER_FORGOT_PASSWORD(LoginForm));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (success) {
      // setTimeout(() => {
      //   dispatch({ type: USER_RESET_PASSWORD_RESET });
      //   Navigate("/login")
      // }, 6000);
    }
  }, [error, dispatch, Navigate, success]);

  return (
    <>
      {/* navbar Section */}
      <Navbar2 Page={"Login"} />
      {/* Fixed Menu Section */}
      {loading ? <Loading /> : ""}
      <section className={Styles.Login}>
        <div className={Styles.container}>
          <div className={Styles.wrapper}>
            <div className={Styles.title}>
              <h1 className="Font_Rajdhani">Forgot Password </h1>
              <p className="Font_Rajdhani_normal">To Change Your Password</p>
            </div>
            {success ? (
              <h4
                style={{
                  color: "green",
                  textAlign: "center",
                  padding: "30px 20px",
                }}
              >
                {success}
              </h4>
            ) : (
              <form onSubmit={handleLogin}>
                <div className={Styles.row}>
                  <i className="fas fa-user"></i>
                  <input
                    name="EmailAddress"
                    value={LoginForm.EmailAddress}
                    onChange={handleChange}
                    type="text"
                    placeholder="Email Address"
                    required
                  />
                </div>
                {/* <div className={Styles.row}>
                <i className="fas fa-lock"></i>
                <input
                  name="Password"
                  value={LoginForm.Password}
                  onChange={handleChange}
                  type={PasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  required
                />
                {PasswordVisible ? (
                  <span
                    className={Styles.VisiblePass}
                    onClick={() => setPasswordVisible(!PasswordVisible)}
                  >
                    <AiOutlineEye />
                  </span>
                ) : (
                  <span
                    className={Styles.VisiblePass}
                    onClick={() => setPasswordVisible(!PasswordVisible)}
                  >
                    <AiOutlineEyeInvisible />
                  </span>
                )}
              </div>
              <div className={Styles.pass}>
                <a href="#">Forgot password?</a>
              </div> */}
                <div className={(Styles.row, Styles.button)}>
                  <input
                    type="submit"
                    value={loading ? "Loading..." : "RESET"}
                  />
                </div>
                {error ? (
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                ) : (
                  ""
                )}

                {success ? (
                  <p style={{ color: "green", textAlign: "center" }}>
                    {success}
                  </p>
                ) : (
                  ""
                )}
                <div className={Styles.signup_link}>
                  Not a member ? <Link to="/signup">Signup now</Link>
                </div>
              </form>
            )}
          </div>
        </div>
      </section>
      {/* Top Footer Section*/}
      <TopFooter />
      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default PasswordForgot;
