import { IoIosCloseCircle } from "react-icons/io";
import "./BookADemo.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { listCourses } from "../../Action/CourseAction";
import { Book_Demo } from "../../Action/BookDemoAction";
import { BookForm_Model } from "../../Model/HomeModel";
import { BOOK_A_DEMO_RESET } from "../../Constant/BookDemoConstant";

export default function BookADemo({ setBookADemoActive }) {
  const { CouresesRec } = useSelector((s) => s.courseListAllRecReducer);
  const { loading, error, success } = useSelector((s) => s.Book_Demo_Reducer);
  const [BookForm, setBookForm] = useState(BookForm_Model);
  const dispatch = useDispatch();

  const handleSubmitDemo = (e) => {
    e.preventDefault();

    dispatch(Book_Demo({ ...BookForm, link: window.location.href }));
  };

  const handleChange = (e) => {
    e.preventDefault();

    setBookForm({ ...BookForm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(listCourses());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setBookForm(BookForm_Model);
        dispatch({ type: BOOK_A_DEMO_RESET });
        setBookADemoActive(false);
      }, 3000);
    }
  }, [success]);
  return (
    <>
      <div
        id="CreatContact"
        style={{ width: "100%" }}
        className={"overlay-note middle-hand w100 BookDemo"}
      >
        <div className="container" id="booking-container">
          <h1>Book Your Demo</h1>
          <div
            onClick={() => {
              setBookADemoActive(false);
              setBookForm(BookForm_Model);
            }}
            className="closeBtn_Book"
          >
            <span>
              <IoIosCloseCircle />
            </span>
          </div>
          <form
            id="appointment-form"
            onSubmit={handleSubmitDemo}
            className="Form_Appointment"
          >
            <div>
              <div className="form-group">
                <label for="name">First Name:</label>
                <input
                  type="text"
                  id="name"
                  name="First_Name"
                  value={BookForm?.First_Name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label for="Last_Name">Last Name:</label>
                <input
                  type="text"
                  id="Last_Name"
                  name="Last_Name"
                  value={BookForm?.Last_Name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div>
              <div className="form-group">
                <label for="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="EmailAddress"
                  value={BookForm?.EmailAddress}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label for="date">Phone No:</label>
                <input
                  type="number"
                  id="Phone"
                  name="PhoneNo"
                  value={BookForm?.PhoneNo}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div>
              <div className="form-group">
                <label for="date">Preferred Date:</label>
                <input
                  type="date"
                  id="date"
                  name="BookingDate"
                  value={BookForm?.BookingDate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label for="time">Preferred Time:</label>
                <input
                  type="time"
                  id="time"
                  name="BookingTime"
                  value={BookForm?.BookingTime}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div>
              <div className="form-group">
                <label for="date">Interested Courses:</label>
                <select
                  id="course"
                  name="CourseInterestedIn"
                  value={BookForm?.CourseInterestedIn}
                  onChange={handleChange}
                  required
                >
                  <option>Select Course</option>
                  {CouresesRec?.map((ev) => (
                    <option value={ev?._id} key={ev?._id}>
                      {ev?.CourseName}
                    </option>
                  ))}
                </select>
              </div>
              <div></div>
            </div>
            <div className="Book_A_demo_btn">
              <button type="submit" disabled={loading}>
                {loading ? "Loading..." : "Book A Demo"}
              </button>
            </div>
          </form>
          <div id="message">
            {error ? <p style={{ color: "red" }}>{error}</p> : ""}
            {success ? <p style={{ color: "green" }}>{success}</p> : ""}
          </div>
        </div>
        {/* <div id="popup" className="popup sticky">
          <div className="popup-content">
            <div className="social-icons">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
