import {
  CLASS_MODE_LIST_FAILED,
  CLASS_MODE_LIST_REQ,
  CLASS_MODE_LIST_SUCCESS,
  CLASS_TIMING_LIST_FAILED,
  CLASS_TIMING_LIST_REQ,
  CLASS_TIMING_LIST_SUCCESS,
} from "../Constant/CommonEnumsConstant";
import api from "../Core/api";

export const listClassTiming = () => async (dispatch) => {
  try {
    dispatch({ type: CLASS_TIMING_LIST_REQ });
    const { data } = await api.get("/commonEnums/classTimings/");

    dispatch({ type: CLASS_TIMING_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CLASS_TIMING_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const Timings = (Data) => async (dispatch) => {

  try {
    dispatch({ type: CLASS_TIMING_LIST_REQ });
    const { data } = await api.get(
      `/student/Timings?courseid=${Data?.courseid}&classmodeid=${
        Data?.classmodeid
      }&centerlocation=${Data?.centerlocation ? Data?.centerlocation : ""}`
    );
    

    dispatch({ type: CLASS_TIMING_LIST_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CLASS_TIMING_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};

export const listCalssMode = () => async (dispatch) => {
  try {
    dispatch({ type: CLASS_MODE_LIST_REQ });
    const { data } = await api.get(`/commonEnums/ClassMode`);
    dispatch({
      type: CLASS_MODE_LIST_SUCCESS,
      payload: data.Result,
    });
  } catch (error) {
    dispatch({
      type: CLASS_MODE_LIST_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
