import { useNavigate } from "react-router-dom";
import { Get_Package_Type } from "../Model/HomeModel";

export default function PackageCard({
  el,
  Styles,
  setEnrollActive,
  setCourseId,
}) {
  const Navigate = useNavigate();
  const Package_Type = Get_Package_Type(el?.PackageType);

  return (
    <>
      <div key={el?._id} className={Styles.card_design}>
        <div
          style={{ backgroundImage: `url(${el?.Image})` }}
          className={`${Styles.Card1} ${Styles.Card1_Img}`}
        >
          <div>
            {/* <button>Speaking</button> */}
            <button style={{ backgroundColor: Package_Type?.color }}>
              {Package_Type?.name}
            </button>{" "}
          </div>
        </div>
        <div>
          <div>
            <p>
              Duration:
              <span style={{ color: "#0b3eff" }}>
                {" "}
                {el?.Duration} Weeks
              </span>{" "}
            </p>
            <p style={{ fontSize: "19px", fontWeight: "600" }}>
              {el?.Name}
              {/* Speaking & Writing <br /> Intermediate Package 003 */}
            </p>
          </div>
          <hr />
          <div>
            <p>
              <strong>This package Includes : </strong>
            </p>
            <div className={Styles.SpeakingWriting}>
              {el?.LIVE_CLASSES ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_LIVE_CLASSES.svg"
                      alt="icon_LIVE_CLASSES"
                    />
                  </span>
                  <span>{el?.LIVE_CLASSES} LIVE CLASSES </span>
                </p>
              ) : (
                ""
              )}

              {el?.One_On_One_DOUBT_SESSIONS === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                      alt="icon_LIVE_CLASSES"
                    />
                  </span>
                  <span>1:1 DOUBT SESSIONS </span>
                </p>
              ) : (
                ""
              )}

              {el?.DOUBT_SESSIONS === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                      alt="icon_LIVE_CLASSES"
                    />
                  </span>
                  <span> DOUBT SESSIONS</span>
                </p>
              ) : (
                ""
              )}
              {el?.SESSION_TESTS === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_SESSION_TESTS.svg"
                      alt="icon_SESSION_TESTS"
                    />
                  </span>
                  <span>SESSION TESTS </span>
                </p>
              ) : (
                ""
              )}
              {el?.STUDY_MATERIAL_ACCESS === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_STUDY_MATERIAL_ACCESS.svg"
                      alt="icon_STUDY_MATERIAL_ACCESS"
                    />
                  </span>
                  <span> STUDY MATERIAL ACCESS</span>
                </p>
              ) : (
                ""
              )}

              {el?.COMPLETE_MOCK_TEST ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_MOCK_TEST.svg"
                      alt="icon_MOCK_TEST"
                    />
                  </span>
                  <span>{el?.COMPLETE_MOCK_TEST} COMPLETE MOCK TEST</span>
                </p>
              ) : (
                ""
              )}

              {el?.COMPLETE_MOCK_TEST_AI ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_MOCK_TEST_AI.svg"
                      alt="icon_MOCK_TEST"
                    />
                  </span>
                  <span>
                    {el?.COMPLETE_MOCK_TEST_AI} COMPLETE MOCK TEST (POWERED BY
                    AI)
                  </span>
                </p>
              ) : (
                ""
              )}
              {el?.TESTIFYI_REALITY_TEST ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_reality_test.svg"
                      alt="icon_reality_test"
                    />
                  </span>
                  <span>
                    {" "}
                    {el?.TESTIFYI_REALITY_TEST} TESTIFYI REALITY TEST
                  </span>
                </p>
              ) : (
                ""
              )}

              {el?.MASTER_CLASS === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_Master_class.svg"
                      alt="icon_Master_class"
                    />
                  </span>
                  <span>MASTER CLASS</span>
                </p>
              ) : (
                ""
              )}

              {el?.One_On_One_SPEAKING_SESSIONS === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_Speaking_Session.svg"
                      alt="icon_Speaking_Session"
                    />
                  </span>
                  <span>1:1 SPEAKING SESSIONS</span>
                </p>
              ) : (
                ""
              )}

              {el?.LANGUAGE_PRACTICE_TEST ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_LANGUAGE_PRACTICE_TEST.svg"
                      alt="icon_LANGUAGE_PRACTICE_TEST"
                    />
                  </span>
                  <span>
                    {el?.LANGUAGE_PRACTICE_TEST} TESTIFYI PRACTICE TEST
                  </span>
                </p>
              ) : (
                ""
              )}

              {el?.GRAMMAR_TEST === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_GRAMMAR_TEST.svg"
                      alt="icon_GRAMMAR_TEST"
                    />
                  </span>
                  <span>GRAMMAR TEST</span>
                </p>
              ) : (
                ""
              )}

              {el?.MOCK_INTERVIEW === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_Mock_INTERVIEW.svg"
                      alt="icon_Mock_INTERVIEW"
                    />
                  </span>
                  <span>MOCK INTERVIEW</span>
                </p>
              ) : (
                ""
              )}

              {el?.GROUP_DISCUSSIONS === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_GROUP_DISCUSSIONS.svg"
                      alt="icon_GROUP_DISCUSSIONS"
                    />
                  </span>
                  <span>GROUP DISCUSSIONS</span>
                </p>
              ) : (
                ""
              )}

              {el?.BUSINESS_ENGLISH_SESSIONS === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_BUSINESS_ENGLISH_SESSIONS.svg"
                      alt="icon_BUSINESS_ENGLISH_SESSIONS"
                    />
                  </span>
                  <span>BUSINESS ENGLISH SESSIONS</span>
                </p>
              ) : (
                ""
              )}

              {el?.SOFT_SKILLS_SESSION === "Yes" ? (
                <p>
                  <span>
                    <img
                      src="/assets/image/ProductCard-Icon/icon_SOFT_SKILLS_SESSION.svg"
                      alt="icon_SOFT_SKILLS_SESSION"
                    />
                  </span>
                  <span>SOFT SKILLS SESSION</span>
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <hr />
          <div className={Styles.Card_Menu}>
            {el?.DiscountedPrice ? (
              <div>
                <p className={Styles.ActualCost}>₹ {el?.DiscountedPrice} /-</p>
                <p className={Styles.LineThrough}>₹ {el?.ActualCost} /- </p>
              </div>
            ) : (
              <div>
                <p className={Styles.ActualCost}>₹ {el?.ActualCost} /-</p>
                <p> </p>
              </div>
            )}
            <div>
              <button onClick={() => Navigate(`/Course/details/${el?._id}`)}>
                View
              </button>
              <button
                onClick={() => {
                  setEnrollActive(el?._id);
                  setCourseId(el?.CourseId);
                }}
              >
                Enroll Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
