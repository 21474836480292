import {
  CONTACT_BOOKING_FAILED,
  CONTACT_BOOKING_REQ,
  CONTACT_BOOKING_SUCCESS,
} from "../Constant/ContactConstant";
import api from "../Core/api";

export const Contact_Us = (ContactDet) => async (dispatch) => {
  try {
    dispatch({ type: CONTACT_BOOKING_REQ });
    const { data } = await api.post("/contactus", ContactDet);
    dispatch({ type: CONTACT_BOOKING_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: CONTACT_BOOKING_FAILED,
      payload:
        error.response && error?.response?.data?.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
