import BottomMenu from "../../Components/BootmMenu";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import Styles from "./Home.module.css";
import { TbDiscountCheckFilled, TbTargetArrow } from "react-icons/tb";
import { TfiDirectionAlt } from "react-icons/tfi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HiBadgeCheck } from "react-icons/hi";
import { PiChatsLight, PiWatch } from "react-icons/pi";
import { TfiWorld } from "react-icons/tfi";
import TopFooter from "../../Components/TopFooterSection";
import { BiLoader } from "react-icons/bi";
import {
  BsArrowRightCircle,
  BsCalendar4Week,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { useState } from "react";
import { MdLocationOn } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { IoNewspaperOutline } from "react-icons/io5";
import { PiVideo } from "react-icons/pi";
import { LuFileSpreadsheet } from "react-icons/lu";

import { HiOutlineAnnotation, HiOutlineClipboardCopy } from "react-icons/hi";
import {
  BestSellingCourseModel,
  BookCardModel,
  LiveMockModel,
} from "../../Model/HomeModel";
import Slider from "react-slick";
import SliderArrow1 from "../../Components/SliderArrow1";
import SliderArrow2 from "../../Components/SliderArrow2";
export default function Home() {
  const [BestSellingCourse, setBestSellingCourse] = useState(
    BestSellingCourseModel
  );
  const [MockLive, setMockLive] = useState(LiveMockModel);
  const [BookCard, setBookCard] = useState(BookCardModel);
  const Mobile_Screen = window.innerWidth;
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow: Mobile_Screen <= 600 ? 1 : 2,
    slidesToScroll: 1,
    nextArrow: <SliderArrow1 />,
    prevArrow: <SliderArrow2 />,
  };

  const BookCardSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow: Mobile_Screen <= 480 ? 1 : Mobile_Screen <= 780 ? 3 : 4,
    slidesToScroll: 1,
    nextArrow: <SliderArrow1 />,
    prevArrow: <SliderArrow2 />,
  };

  const CountryCardSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow: Mobile_Screen <= 600 ? 1 : Mobile_Screen <= 800 ? 2 : 4,
    slidesToScroll: 1,
    nextArrow: <SliderArrow1 />,
    prevArrow: <SliderArrow2 />,
  };

  return (
    <>
      {/* navbar Section */}
      <Navbar />
      {/* Fixed Menu Section */}
      <BottomMenu />
      {/* Banner Section */}

      <section className={Styles.Margin}>
        <div className={Styles.Banner_Section}>
          <div>
            <div>
              <p
                className="Font_Rajdhani"
                style={{ color: "#0b3eff", fontWeight: "600" }}
              >
                TESTIFYI
              </p>
              <h1 className={Styles.Font_Rajdhani}>
                Best Platform For English <br /> Language Preparation To Study
                Abroad
              </h1>
              <div>
                {" "}
                <hr className={Styles.Hr45} />
              </div>

              <p className={Styles.IELTS}>
                {" "}
                <span>
                  {" "}
                  <TfiDirectionAlt />{" "}
                </span>{" "}
                <span> Best PTE Guidance Online</span>
              </p>
              <p className={Styles.IELTS}>
                {" "}
                <span>
                  <TbTargetArrow />{" "}
                </span>{" "}
                <span> 2000+ IELTS Practice Modules</span>
              </p>
            </div>
          </div>
          <div>
            <img src="/assets/image/IELTS_Banner.png" alt="banner_ielts" />
          </div>
        </div>
      </section>

      {/* Scrolled Section 2 */}
      <section style={{ width: "100%", margin: "auto", overflow: "hidden" }}>
        <div className={Styles.Rotation}>
          <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>One to One Speaking Assignment</span>
          </div>
          <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>One to One Speaking Assignment</span>
          </div>
          {Mobile_Screen >= 600 ? (
            <>
              {" "}
              <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
                <span>
                  <HiBadgeCheck />
                </span>
                <span>One to One Speaking Assignment</span>
              </div>
              <div className={(Styles.Loop_text, Styles.marqueeStyle)}>
                <span>
                  <HiBadgeCheck />
                </span>
                <span>One to One Speaking Assignment</span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </section>
      {/* section 3 */}
      <section style={{ background: "#f4f9ff" }} className={Styles.Margin2}>
        <div className={Styles.section3_Top_heading}>
          <div>
            <h1 className={Styles.Heading}>
              <span className={Styles.Font_Rajdhani}>
                {" "}
                Best Selling Courses For <br /> English Language Preparation
              </span>
            </h1>
          </div>
          <div>
            <select>
              <option>All Courses</option>
              <option>IELTS</option>
              <option>PTE</option>
              <option>General</option>
            </select>
          </div>
        </div>
        <div className={Styles.Section3_Card_Section}>
          {BestSellingCourse?.map((el, i) => (
            <div key={i + 78} className={Styles.card_design}>
              <div
                className={`${Styles.Card1} ${
                  i % 2 === 0 ? Styles.Card1_Img : Styles.Card2_Img
                }`}
              >
                <div>
                  <button>Speaking</button>
                  <button>Writing</button>{" "}
                </div>
              </div>
              <div>
                <div>
                  <p>
                    Duration:
                    <span style={{ color: "#0b3eff" }}>9 Hrs 10 Min</span>{" "}
                  </p>
                  <p style={{ fontSize: "19px", fontWeight: "600" }}>
                    Speaking & Writing <br /> Intermediate Package 003
                  </p>
                </div>
                <hr />
                <div>
                  <p>
                    <strong>This package Includes : </strong>
                  </p>
                  <div className={Styles.SpeakingWriting}>
                    <p>
                      <span>
                        <PiVideo />
                      </span>
                      <span>6 Test Papers </span>
                    </p>
                    <p>
                      <span>
                        <LuFileSpreadsheet />
                      </span>
                      <span>2 Live mock Test </span>
                    </p>
                    <p>
                      <span>
                        <HiOutlineAnnotation />
                      </span>
                      <span> 2 Counselling Session</span>
                    </p>
                    <p>
                      <span>
                        <IoNewspaperOutline />
                      </span>
                      <span>4 Cheat Sheets </span>
                    </p>
                    <p>
                      <span>
                        <HiOutlineClipboardCopy />
                      </span>
                      <span> 12 Lessons</span>
                    </p>
                  </div>
                </div>
                <hr />
                <div className={Styles.Card_Menu}>
                  <div>
                    <p>₹ 299.00 /-</p>
                    <p>₹ 299.00 /- </p>
                  </div>
                  <div>
                    <button> Buy Now</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={Styles.ViewAll}>
          <button>View All Courses</button>
        </div>
      </section>
      {/* section 4 */}
      <section className={Styles.Section4}>
        <div className={Styles.Margin2}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "8% 0",
            }}
          >
            <div>
              <p style={{ color: "white", fontWeight: "600" }}>
                Curated Package
              </p>
              <div>
                <h1
                  className={Styles.Section4_heading}
                  style={{ color: "white", fontSize: "45px" }}
                >
                  <span>
                    {" "}
                    Curated Package For IELTS &<br /> PTE By Language Experts{" "}
                  </span>
                </h1>
              </div>
              <div className={Styles.Section4_Text}>
                <hr className={Styles.Hr1} />
                <div>
                  <p>
                    <PiChatsLight />
                  </p>
                  <p>
                    Live Speaking <br /> Session With Faculty
                  </p>
                </div>
                <hr className={Styles.Hr1} />
                <div>
                  <p>
                    <TfiWorld />
                  </p>
                  <p>
                    IELTS-Like <br />
                    Testing Environment
                  </p>
                </div>
                <hr className={Styles.Hr1} />
                <div>
                  <p>
                    <TfiWorld />
                  </p>
                  <p>
                    IELTS Score Predicator <br /> & Full-length tests
                  </p>
                </div>
                <hr className={Styles.Hr1} />
              </div>
              <div style={{ padding: "40px 0px" }}>
                <button className={Styles.custom_btn}>View Lectures</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  Section 5   */}

      <section style={{ background: "#ffffff" }}>
        <div className={Styles.Margin2}>
          <div className={Styles.Section4_Text2}>
            <div>
              <h1 style={{ fontSize: "30px", fontWeight: "700" }}>
                <span className={Styles.Font_Rajdhani}>Enroll in Upcoming</span>
                <br />{" "}
                <span
                  className={Styles.Font_Rajdhani}
                  style={{ color: "#0b3eff" }}
                >
                  Live Mock Tests{" "}
                </span>
              </h1>
            </div>

            <div>
              <p>
                In this free inactive webinar, Our IELTS export will help us be
                ready to
                <br /> answer a range of common questions types for part1 of the
                speaking Test.
              </p>
            </div>
          </div>

          <div>
            {MockLive.map((el, i) => (
              <div key={i + 80} className={Styles.Section5_Card}>
                <div
                  className={i % 2 === 0 ? Styles.SideColor : Styles.SideColor2}
                ></div>
                <div className={Styles.Calender}>
                  <p>Date</p>
                  <p>
                    <span>
                      <BsCalendar4Week />{" "}
                    </span>
                    <span>10 July 2020</span>
                  </p>
                </div>
                <div className={Styles.Hr_Line}>
                  <hr className={Styles.Hr3} />
                </div>
                <div className={Styles.Calender}>
                  <p>Duration</p>
                  <p>
                    <span>
                      <PiWatch />
                    </span>
                    <span>2 hr 30 min</span>
                  </p>
                </div>
                <div className={Styles.Hr_Line}>
                  <hr className={Styles.Hr3} />
                </div>
                <div>
                  <p> Writing Mock Test</p>
                  <p>
                    <span>For IELTS</span>
                  </p>
                </div>
                <div className={Styles.Hr_Line}>
                  <hr className={Styles.Hr3} />
                </div>
                <div className={Styles.Calender}>
                  <p>Location</p>
                  <p>
                    <span>
                      <MdLocationOn />
                    </span>
                    <span>Noida</span>
                  </p>
                </div>
                <div className={Styles.Hr_Line}>
                  <hr className={Styles.Hr3} />
                </div>

                <div className={Styles.EnrollBtn}>
                  <button>Enroll Now</button>
                </div>
              </div>
            ))}

            <div className={Styles.Load_More}>
              <button>
                <span style={{ color: "white" }}>
                  <BiLoader />{" "}
                </span>{" "}
                Know More
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Section 6 */}
      <section style={{ background: "#0b3eff" }}>
        <div className={Styles.Margin2}>
          <div
            className={Styles.Section_6_Main}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "8% 0",
            }}
          >
            <div className={Styles.Section_6}>
              <div>
                <h1 className={Styles.Font_Rajdhani}>
                  Mindfully Compiled & <br /> Curate English{" "}
                  <span>Test Papers</span>
                </h1>
              </div>
              <div>
                <p>
                  {" "}
                  You can purchase package and submit the writing, you will{" "}
                  <br /> receive the checked script within 48 hours of your
                  submission
                  <br />
                  along with the suggestion for improvement.
                </p>
              </div>

              <div className={Styles.Section4_Text}>
                <hr />
                <div>
                  <p style={{ color: "white", fontSize: "20px" }}>
                    <PiChatsLight />
                  </p>
                  <p>
                    Live Speaking
                    <br /> Session with Faculty
                  </p>
                </div>
                <hr />
                <div>
                  <p style={{ color: "white", fontSize: "20px" }}>
                    <TfiWorld />
                  </p>

                  <p>
                    IELTS-Like
                    <br /> Testing Environment
                  </p>
                </div>
                <hr />
              </div>
            </div>
            <div
              className={Styles.Section6_Slider}
              style={{ width: "50%", overflow: "hidden" }}
            >
              <Slider style={{ width: "100%" }} {...settings}>
                <div>
                  <div className={Styles.Slider_Card}>
                    <div>
                      <img src="/assets/image/PTE.png" alt="PTE" />
                    </div>
                    <div>
                      <h1 className="Font_Rajdhani">
                        PTE Practice
                        <br /> Paper
                      </h1>
                    </div>
                    <div>
                      <p>
                        {" "}
                        <span className="Font_Rajdhani">
                          {" "}
                          Explore More{" "}
                        </span>{" "}
                        <span>
                          <BsArrowRightCircle />{" "}
                        </span>
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className={Styles.Slider_Card}>
                    <div>
                      <img src="/assets/image/Ielts.png" alt="IELTS" />
                    </div>
                    <div>
                      <h1 className="Font_Rajdhani">
                        PTE Practice
                        <br /> Paper
                      </h1>
                    </div>
                    <div>
                      <p>
                        {" "}
                        <span className="Font_Rajdhani">
                          {" "}
                          Explore More{" "}
                        </span>{" "}
                        <span>
                          <BsArrowRightCircle />{" "}
                        </span>
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className={Styles.Slider_Card}>
                    <div>
                      <img src="/assets/image/PTE.png" alt="PTE" />
                    </div>
                    <div>
                      <h1 className="Font_Rajdhani">
                        PTE Practice
                        <br /> Paper
                      </h1>
                    </div>
                    <div>
                      <p>
                        {" "}
                        <span className="Font_Rajdhani">
                          {" "}
                          Explore More{" "}
                        </span>{" "}
                        <span>
                          <BsArrowRightCircle />{" "}
                        </span>
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className={Styles.Slider_Card}>
                    <div>
                      <img src="/assets/image/Ielts.png" alt="Ielts" />
                    </div>
                    <div>
                      <h1 className="Font_Rajdhani">
                        PTE Practice
                        <br /> Paper
                      </h1>
                    </div>
                    <div>
                      <p>
                        <span className="Font_Rajdhani"> Explore More </span>{" "}
                        <span>
                          <BsArrowRightCircle />{" "}
                        </span>
                      </p>
                    </div>
                  </div>{" "}
                </div>
              </Slider>
            </div>{" "}
          </div>
        </div>
      </section>

      {/* section 7 */}
      <section className={Styles.Section4}>
        <div className={Styles.Margin2}>
          <div
            className={Styles.Section_7_style}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: "8% 0",
            }}
          >
            <div>
              <p style={{ color: "#0b3eff", fontWeight: "600" }}>Upgrade</p>
              <div>
                <h1
                  className={Styles.Heading7}
                  style={{ color: "white", fontSize: "35px" }}
                >
                  <span className={Styles.Font_Rajdhani}>
                    {" "}
                    One To One &<br /> Counselling Session
                  </span>
                </h1>
              </div>
              <div className={Styles.Section4_Text}>
                <hr className={Styles.Hr1} />
                <div style={{ fontSize: "18px" }}>
                  <p style={{ color: "#42d96b", fontSize: "25px" }}>
                    <TbDiscountCheckFilled />
                  </p>
                  <p>
                    Comprehensive <br /> analytics tool
                  </p>
                </div>
                <hr className={Styles.Hr1} />
                <div style={{ fontSize: "18px" }}>
                  <p style={{ color: "#42d96b", fontSize: "25px" }}>
                    <TbDiscountCheckFilled />
                  </p>
                  <p>
                    Increased Your <br />
                    IELTS band Score
                  </p>
                </div>
                <hr className={Styles.Hr1} />
                <div style={{ fontSize: "18px" }}>
                  <p style={{ color: "#42d96b", fontSize: "25px" }}>
                    <TbDiscountCheckFilled />
                  </p>
                  <p>
                    Take Recent <br /> Actual IELTS Tests
                  </p>
                </div>
                <hr className={Styles.Hr1} />
              </div>
              <div
                style={{ padding: "40px 0px", display: "flex", gap: "20px" }}
              >
                <button
                  className={Styles.custom_btn}
                  style={{
                    background: "transparent",
                    color: "white",
                    border: "0.1px solid white",
                  }}
                >
                  Free Meet
                </button>
                <button className={Styles.custom_btn}>Paid Counselling</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 8 */}

      <section>
        <div className={Styles.Margin2}>
          <div
            style={{
              margin: "8% 0",
            }}
          >
            {" "}
            <div>
              <h1 style={{ fontSize: "30px" }} className={Styles.Font_Rajdhani}>
                <span style={{ color: "#0b3eff" }}>Enhance Skills </span>{" "}
                <span>With Expert Advice</span>
              </h1>

              <p style={{ lineHeight: "25px" }}>
                The writing and the speaking services are paid and it will also{" "}
                contain the detailed feedback alike the other modules,
                <br />
                in the writing test,you can purchase package and submit the
                writing, you will receive the checked script within 48
                <br /> hours of your submission along with the suggestions for
                improvement.
              </p>
            </div>
            <div
              className={Styles.Section_8}
              style={{
                paddingTop: "50px",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div className={Styles.BlogCard}>
                <div>
                  <img src="/assets/image/Crash-course.jpg" alt="blogs" />
                </div>
                <div>
                  <p>Blog</p>
                  <h4>
                    Free IELTS Practice Test:
                    <br />
                    PDFs and More
                  </h4>

                  <p>READ MORE</p>
                </div>
              </div>
              <div className={Styles.BlogCard}>
                <div>
                  <img src="/assets/image/Crash-course.jpg" alt="blogs" />
                </div>
                <div>
                  <p>Blog</p>
                  <h4>
                    Free IELTS Practice Test:
                    <br />
                    PDFs and More
                  </h4>

                  <p>READ MORE</p>
                </div>
              </div>
              <div className={Styles.BlogCard}>
                <div>
                  <img src="/assets/image/Crash-course.jpg" alt="blogs" />
                </div>
                <div>
                  <p>Blog</p>
                  <h4>
                    Free IELTS Practice Test:
                    <br />
                    PDFs and More
                  </h4>

                  <p>READ MORE</p>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </section>

      {/*section 9*/}

      <section style={{ background: "#f4f9ff" }}>
        <div className={Styles.Margin3}>
          <div className={Styles.section_9}>
            <div>
              <div>
                <h1 className={Styles.Font_Rajdhani}>
                  Best IELTS & PTE <span> Books & E-Books </span> Online
                </h1>
              </div>
              <div>
                <button>View All Books</button>
              </div>
            </div>
            <div style={{ overflow: "hidden" }}>
              <Slider {...BookCardSettings}>
                {BookCard.map((el, i) => (
                  <div key={i + 67}>
                    <div className={Styles.BookCard}>
                      <div style={{ position: "relative" }}>
                        <button className={Styles.EBook}>E-Book</button>
                        <img
                          src="/assets/image/IELTS_BOOK.png"
                          alt="IELTS_Book"
                        />
                      </div>
                      <div>
                        <p>
                          Original Grammar for
                          <br /> IELTS writing Book
                        </p>
                        <p
                          className={
                            el.Price !== "Free" ? Styles.Paid : Styles.Free
                          }
                        >
                          {el.Price !== "Free" ? `₹ ${el.Price}.00 /-` : "Free"}
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* section 10 */}
      <section>
        <div>
          <div className={Styles.Section_10}>
            <div className={Styles.Section_6}>
              <p style={{ margin: "0", padding: "0", fontSize: "14px" }}>
                WANT HELP WITH APPLICATION
              </p>
              <div>
                <h1 className={Styles.Font_Rajdhani}>
                  Get Experts Guidance <br /> By Consultants
                </h1>
              </div>
              <div>
                <p style={{ lineHeight: "25px" }}>
                  {" "}
                  IELTS Video Course is Specially designed for working finding
                  <br /> had to allocate a fixed time every day for IELTS
                  Classes.
                </p>
              </div>

              <div className={Styles.Section4_Text}>
                <hr />
                <div>
                  <p style={{ color: "white", fontSize: "20px" }}>
                    <PiChatsLight />
                  </p>
                  <p>
                    Live Speaking
                    <br /> Session with Faculty
                  </p>
                </div>
                <hr />
                <div>
                  <p style={{ color: "white", fontSize: "20px" }}>
                    <TfiWorld />
                  </p>

                  <p>
                    IELTS-Like
                    <br /> Testing Environment
                  </p>
                </div>
                <hr />
              </div>
              <div className={Styles.ExploreBtn}>
                <button>Explore More</button>
              </div>
            </div>
            <div className={Styles.Section_6}>
              <p style={{ margin: "0", padding: "0", fontSize: "14px" }}>
                NO CREDIT CARD ?
              </p>
              <div>
                <h1 className={Styles.Font_Rajdhani}>
                  Need Help in Filling <br /> PTE For Your ?
                </h1>
              </div>
              <div>
                <p style={{ lineHeight: "25px" }}>
                  IELTS Video Course is Specially designed for working finding
                  <br /> had to allocate a fixed time every day for IELTS
                  Classes.
                </p>
              </div>

              <div className={Styles.Section4_Text}>
                <hr />
                <div>
                  <p style={{ color: "white", fontSize: "20px" }}>
                    <PiChatsLight />
                  </p>
                  <p>
                    Live Speaking
                    <br /> Session with Faculty
                  </p>
                </div>
                <hr />
                <div>
                  <p style={{ color: "white", fontSize: "20px" }}>
                    <TfiWorld />
                  </p>

                  <p>
                    IELTS-Like
                    <br /> Testing Environment
                  </p>
                </div>
                <hr />
              </div>
              <div className={Styles.ExploreBtn}>
                <button>Explore More</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 11 */}
      <section>
        <div>
          <div>
            <div className={Styles.Country_Section_11}>
              <div>
                <p>Featured College</p>
                <h1 className={Styles.Font_Rajdhani}>Want To Study Abroad</h1>
              </div>
              <div>
                <p>countries</p>
                <div></div>
              </div>
            </div>
            <div
              className={Styles.CountrySlider}
              style={{ overflow: "hidden" }}
            >
              <Slider {...CountryCardSettings}>
                <div>
                  {" "}
                  <div className={Styles.CountryCard}>
                    <div style={{ position: "relative" }}>
                      <img src="/assets/image/Johns.jpg" alt="country" />
                      <div className={Styles.Country_Logo}>
                        <img src="/assets/image/Johns_logo.png" alt="logo" />
                      </div>
                    </div>
                    <div>
                      <h3>Johns Hopkins University</h3>
                      <p>
                        <span>
                          {" "}
                          <MdLocationOn />{" "}
                        </span>{" "}
                        <span>Baltimore, MD</span>{" "}
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className={Styles.CountryCard}>
                    <div style={{ position: "relative" }}>
                      <img src="/assets/image/Johns.jpg" alt="country" />
                      <div className={Styles.Country_Logo}>
                        <img src="/assets/image/Johns_logo.png" alt="logo" />
                      </div>
                    </div>
                    <div>
                      <h3>Johns Hopkins University</h3>
                      <p>
                        <span>
                          {" "}
                          <MdLocationOn />{" "}
                        </span>{" "}
                        <span>Baltimore, MD</span>{" "}
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className={Styles.CountryCard}>
                    <div style={{ position: "relative" }}>
                      <img src="/assets/image/Johns.jpg" alt="country" />
                      <div className={Styles.Country_Logo}>
                        <img src="/assets/image/Johns_logo.png" alt="logo" />
                      </div>
                    </div>
                    <div>
                      <h3>Johns Hopkins University</h3>
                      <p>
                        <span>
                          {" "}
                          <MdLocationOn />{" "}
                        </span>{" "}
                        <span>Baltimore, MD</span>{" "}
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className={Styles.CountryCard}>
                    <div style={{ position: "relative" }}>
                      <img src="/assets/image/Johns.jpg" alt="country" />
                      <div className={Styles.Country_Logo}>
                        <img src="/assets/image/Johns_logo.png" alt="logo" />
                      </div>
                    </div>
                    <div>
                      <h3>Johns Hopkins University</h3>
                      <p>
                        <span>
                          {" "}
                          <MdLocationOn />{" "}
                        </span>{" "}
                        <span>Baltimore, MD</span>{" "}
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div>
                  {" "}
                  <div className={Styles.CountryCard}>
                    <div style={{ position: "relative" }}>
                      <img src="/assets/image/Johns.jpg" alt="country" />
                      <div className={Styles.Country_Logo}>
                        <img src="/assets/image/Johns_logo.png" alt="logo" />
                      </div>
                    </div>
                    <div>
                      <h3>Johns Hopkins University</h3>
                      <p>
                        <span>
                          {" "}
                          <MdLocationOn />{" "}
                        </span>{" "}
                        <span>Baltimore, MD</span>{" "}
                      </p>
                    </div>
                  </div>{" "}
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className={Styles.Margin2}>
          <hr className={Styles.Hr11} />
        </div>
      </section>
      {/* section 12 */}
      <section>
        <div className={Styles.Margin2}>
          <div className={Styles.Section_12}>
            <div>
              <h1 className={Styles.Font_Rajdhani}>
                Testifyi Experts Services{" "}
              </h1>
              <p>
                At Testifyi we ardently believe that an association is for a
                lifetime and in our endeavor to stand true to our beliefs.
              </p>
            </div>
            <div className={Styles.VisaCardDesign}>
              <div className={Styles.VisaCard}>
                <div>
                  <img src="/assets/image/Visa_Logo.png" alt="visa_logo" />
                </div>
                <div>
                  <h1>
                    Getting Visa <br /> Guidance
                  </h1>
                  <h1>
                    <BsFillArrowRightCircleFill />
                  </h1>
                </div>
              </div>
              <hr className={Styles.Hr12} />
              <div className={Styles.VisaCard}>
                <div>
                  <img
                    src="/assets/image/i_Visa_Guide_copy.png"
                    alt="Visa Guide"
                  />
                </div>
                <div>
                  <h1>
                    Helth <br /> Insurance
                  </h1>
                  <h1>
                    <BsFillArrowRightCircleFill />
                  </h1>
                </div>
              </div>
              <hr className={Styles.Hr12} />
              <div className={Styles.VisaCard}>
                <div>
                  <img
                    src="/assets/image/i_Scholarship_assistance.png"
                    alt="i_Scholarship assistance"
                  />
                </div>
                <div>
                  <h1>
                    Scholarship <br /> assistance
                  </h1>
                  <h1>
                    <BsFillArrowRightCircleFill />
                  </h1>
                </div>
              </div>
              <hr className={Styles.Hr12} />
              <div className={Styles.VisaCard}>
                <div>
                  <img
                    src="/assets/image/i_Course_and_career_guide.png"
                    alt="visa_logo"
                  />
                </div>
                <div>
                  <h1>
                    Course & Career <br /> Counselling
                  </h1>
                  <h1>
                    <BsFillArrowRightCircleFill />
                  </h1>
                </div>
              </div>
            </div>
            <div style={{ padding: "40px 0" }}>
              <hr className={Styles.Hr12} />
              <div className={Styles.Accommodation}>
                <div>
                  <img  src="/assets/image/Accomodation.png" alt="Accommodation" />
                  <h3>Accommodation</h3>
                  <h1>
                    <BsFillArrowRightCircleFill />
                  </h1>
                </div>
                <hr />
                <div>
                <img  src="/assets/image/Loan.png" alt="Loan" />
                  <h3>Loans</h3>
                  <h1>
                    <BsFillArrowRightCircleFill />
                  </h1>
                </div>
                <hr />
                <div>
                <img  src="/assets/image/Insurance.png" alt="Insurance" />
                  <h3>Insurance</h3>
                  <h1>
                    <BsFillArrowRightCircleFill />
                  </h1>
                </div>
              </div>
              <hr className={Styles.Hr12} />
            </div>
          </div>
        </div>
      </section>

      {/* Top Footer Section*/}
      <TopFooter />
      {/* Footer Section */}
      <Footer />
    </>
  );
}
