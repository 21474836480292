export const Demo_User = [
  {
    Id: "1",
    First_Name: "Admin",

    EmailAddress: "admin@searchedu.in",
    PhoneNo: "9937951227",
    UserName: "admin",
    Password: "admin@123",
  },
];

export const Signup_Form_Model = {
  First_Name: "",
  Last_Name: "",
  EmailAddress: "",
  PhoneNo: "",
  // Password: "",
};

export const Login_Form_Model = {
  EmailAddress: "",
  Password: "",
};

export const PasswordRest_Model = {
  EmailAddress: "",
};
export const Change_Password_Model = {
  Password: "",
  ConfirmPassword: "",
};

export const Signup_Validator = (data) => {
  let Error = "";
  var regularExpression = /^[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  if (!data.Email.includes(".") || !data.Email.includes("@")) {
    Error += "Invalid Email Address ";
  }

  if (!regularExpression.test(data?.Password) || data?.Password?.length < 8) {
    Error +=
      "Password Should be one uppercase , one lowercase, one special & minmum 8 characters long. ";
  }

  if (Error) {
    return Error;
  } else {
    return "success";
  }
};

export const PasswordValidator = (password) => {
  let Error = "";
  var regularExpression =
    /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  if (!regularExpression.test(password) || password?.length < 8) {
    Error =
      "Password Should be one uppercase , one lowercase, one special & minmum 8 characters long. ";
  }

  if (Error) {
    return Error;
  } else {
    return "success";
  }
};
