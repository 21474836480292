import {
  TAKE_A_TEST_FAILED,
  TAKE_A_TEST_REQ,
  TAKE_A_TEST_RESET,
  TAKE_A_TEST_SUBMITTED_FAILED,
  TAKE_A_TEST_SUBMITTED_REQ,
  TAKE_A_TEST_SUBMITTED_RESET,
  TAKE_A_TEST_SUBMITTED_SUCCESS,
  TAKE_A_TEST_SUCCESS,
  TEST_QUESTION_LIST_FAILED,
  TEST_QUESTION_LIST_REQ,
  TEST_QUESTION_LIST_RESET,
  TEST_QUESTION_LIST_SUCCESS,
} from "../Constant/TLatConstant";

export const Take_Test_Det_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TAKE_A_TEST_REQ: {
      return { loading: true };
    }

    case TAKE_A_TEST_SUCCESS: {
      return { loading: false, Test_Dets: payload };
    }

    case TAKE_A_TEST_FAILED: {
      return { loading: false, error: payload };
    }

    case TAKE_A_TEST_RESET: {
      return { loading: false };
    }
    default:
      return state;
  }
};

export const Test_Submitted_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TAKE_A_TEST_SUBMITTED_REQ: {
      return { loading: true };
    }

    case TAKE_A_TEST_SUBMITTED_SUCCESS: {
      return { loading: false, Test_Submit_Det: payload };
    }

    case TAKE_A_TEST_SUBMITTED_FAILED: {
      return { loading: false, error: payload };
    }

    case TAKE_A_TEST_SUBMITTED_RESET: {
      return { loading: false, error: "" };
    }
    default:
      return state;
  }
};

export const Test_Question_List_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TEST_QUESTION_LIST_REQ: {
      return { loading: true };
    }

    case TEST_QUESTION_LIST_SUCCESS: {
      return { loading: false, ListQuestionRecs: payload };
    }

    case TEST_QUESTION_LIST_FAILED: {
      return { loading: false, error: payload };
    }

    case TEST_QUESTION_LIST_RESET: {
      return { loading: false, error: "" };
    }
    default:
      return state;
  }
};
