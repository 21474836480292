import { useEffect, useState } from "react";
import { Routes_React } from "./Routes/NavigationRoute";
import { Link } from "react-router-dom";

export const SitemapGenerator = () => {
  const [PathRoute, setPathRoute] = useState([]);

  useEffect(() => {
    const Paths = [];
    for (let i = 0; i < Routes_React?.props.children.length; i++) {
      Paths.push(Routes_React?.props.children[i].props.path);
    }
    setPathRoute(Paths);
  }, []);

  return (
    <ol>
      {PathRoute?.map((el, i) => (
        <li key={i}>
          <Link to={el} target="_blank" rel="noreferrer">
            {el}
          </Link>
        </li>
      ))}
    </ol>
  );
};
