import {
  CLASS_CENTER_DELETE_FAIL,
  CLASS_CENTER_DELETE_REQUEST,
  CLASS_CENTER_DELETE_SUCCESS,
  CLASS_CENTER_INSERT_FAIL,
  CLASS_CENTER_INSERT_REQUEST,
  CLASS_CENTER_INSERT_SUCCESS,
  CLASS_CENTER_LIST_FAIL,
  CLASS_CENTER_LIST_REQUEST,
  CLASS_CENTER_LIST_SUCCESS,
  CLASS_CENTER_UPDATE_FAIL,
  CLASS_CENTER_UPDATE_REQUEST,
  CLASS_CENTER_UPDATE_SUCCESS,
} from "../Constant/classCenterConstant";
import api from "../Core/api";

export const listClassCenter = () => async (dispatch) => {
  try {
    dispatch({
      type: CLASS_CENTER_LIST_REQUEST,
    });
    const { data } = await api.get(`/student/classcenter`);

    var dt = data.Result;

    dispatch({
      type: CLASS_CENTER_LIST_SUCCESS,
      payload: dt,
    });
  } catch (error) {
    dispatch({
      type: CLASS_CENTER_LIST_FAIL,
      payload:
        error.response && error?.response?.data.message
          ? error.response.data.Result
          : error.message,
    });
  }
};

// export const createClassCenter = (inputJson) => async (dispatch) => {
//   try {
//     dispatch({
//       type: CLASS_CENTER_INSERT_REQUEST,
//     });
//     const { data } = await api.post(`/classcenter`, inputJson);
//     var dt = data.Result;

//     dispatch({
//       type: CLASS_CENTER_INSERT_SUCCESS,
//       payload: dt,
//     });
//   } catch (error) {
//     dispatch({
//       type: CLASS_CENTER_INSERT_FAIL,
//       payload:
//         error.response && error.response.data.Result
//           ? error.response.data.Result
//           : error.message,
//     });
//   }
// };

// export const updateClassCenter = (id, inputJson) => async (dispatch) => {
//   try {
//     dispatch({
//       type: CLASS_CENTER_UPDATE_REQUEST,
//     });
//     const { data } = await api.put(`/classcenter/${id}`, inputJson);
//     var dt = data.Result;
//     if (dt.includes("Error")) {
//       dispatch({
//         type: CLASS_CENTER_UPDATE_FAIL,
//         payload: dt,
//       });
//     } else {
//       dispatch({
//         type: CLASS_CENTER_UPDATE_SUCCESS,
//         payload: dt,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: CLASS_CENTER_UPDATE_FAIL,
//       payload:
//         error.response && error.response.data.Result
//           ? error.response.data.Result
//           : error.message,
//     });
//   }
// };

// export const deleteClassCenter = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: CLASS_CENTER_DELETE_REQUEST,
//     });
//     const { data } = await api.delete(`/classcenter/${id}`);
//     var dt = data.Result;
//     if (dt.includes("Error")) {
//       dispatch({
//         type: CLASS_CENTER_DELETE_FAIL,
//         payload: dt,
//       });
//     } else {
//       dispatch({
//         type: CLASS_CENTER_DELETE_SUCCESS,
//         payload: dt,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: CLASS_CENTER_DELETE_FAIL,
//       payload:
//         error.response && error.response.data.Result
//           ? error.response.data.Result
//           : error.message,
//     });
//   }
// };
