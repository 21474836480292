import { useState } from "react";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import TopFooter from "../../Components/TopFooterSection";
import Styles from "./TestPaper.module.css";
import {
  BsChevronLeft,
  BsChevronRight,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { BestSellingCourseModel } from "../../Model/HomeModel";
import { useEffect } from "react";
import { PiVideo } from "react-icons/pi";
import { LuFileSpreadsheet } from "react-icons/lu";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { IoNewspaperOutline } from "react-icons/io5";
export default function TestPaper() {
  const [TestPapers, setTestPapers] = useState(BestSellingCourseModel);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/*Navbar */}
      <Navbar Page={"PracticePaper"} />
      {/*Banner Section */}
      <div className={Styles.Package}>
        <section className={Styles.showcase}>
          <img src={"/assets/image/Practice-test.jpg"} alt="Banner" />
          <div className={Styles.overlay}>
            <div>
              <div>
                <p>
                  <span> HOME</span> /<span> ALL Courses </span>{" "}
                </p>
              </div>
              <div>
                <h1 className="Font_Rajdhani">Test Paper</h1>
              </div>
              <div>
                <p>
                  We help internationals to get enrolled into the best possible
                  career-oriented courses <br /> along with the selection of
                  colleges and universities across Australia.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/*Section 2*/}
        <section>
          <div className={Styles.Custom_Margin}>
            <div>
              <div>
                <div className={Styles.FilterCard}>
                  <div>
                    <h4>Filter</h4>
                    <button>Clear Filters</button>
                  </div>

                  {/* <hr />
                  <div>
                    <h4>Course Level</h4>
                    <div>
                      <input type="checkbox" /> <p>Beginner</p>
                    </div>
                    <div>
                      <input type="checkbox" />
                      <p>Intermediate</p>
                    </div>
                    <div>
                      <input type="checkbox" />
                      <p>Advance</p>
                    </div>
                  </div> */}

                  <div>
                    <h4>Subject</h4>
                    <div>
                      <input type="checkbox" /> <p>Writing</p>
                    </div>
                    <div>
                      <input type="checkbox" /> <p>Reading</p>
                    </div>
                    <div>
                      <input type="checkbox" /> <p>Speaking</p>
                    </div>
                    <div>
                      <input type="checkbox" /> <p>Listening</p>
                    </div>
                    <div>
                      <input type="checkbox" /> <p>All Subjects</p>
                    </div>
                    <p></p>
                  </div>
                  <hr />
                  <div>
                    <h4>Type</h4>
                    <div>
                      <input type="checkbox" /> <p>Free</p>
                    </div>
                    <div>
                      <input type="checkbox" /> <p>Paid</p>
                    </div>
                    <p></p>
                  </div>
                  <hr style={{ display: "none" }} />
                </div>
                <div className={Styles.Expert}>
                  <img src="/assets/image/Expert.png" alt="expert_contact_us" />
                </div>
              </div>
              <div className={Styles.PackageCard_Container}>
                <div>
                  {TestPapers.map((el, i) => (
                    <div key={i + 79} className={Styles.card_design}>
                      <div
                        className={`${Styles.Card1} ${
                          i % 2 === 0 ? Styles.Card1_Img : Styles.Card2_Img
                        }`}
                      >
                        <div>
                          <button>Writing</button>{" "}
                        </div>
                      </div>
                      <div>
                        <div>
                          {/* <p>
                            Duration:
                            <span
                              style={{ color: "#fa763c", fontWeight: "600" }}
                            >
                              {" "}
                              9 Hrs 10 Min
                            </span>{" "}
                          </p> */}
                          <p style={{ fontSize: "19px", fontWeight: "600" }}>
                            Reading Writing Test <br /> Package 0023
                          </p>
                        </div>
                        <hr />
                        <div className={Styles.TestPackageDet}>
                          <p>
                            <strong>Test package Includes : </strong>
                          </p>
                          <p>
                            <span>
                              <PiVideo />
                            </span>
                            <span>1 Introducation Lesson </span>
                          </p>
                          <p>
                            {" "}
                            <span>
                              <IoNewspaperOutline />
                            </span>
                            <span> 1 Cheat Sheets</span>
                          </p>
                          <p>
                            <span>
                              <LuFileSpreadsheet />
                            </span>{" "}
                            4 Writing Test Papers
                          </p>
                          <p>
                            {" "}
                            <span>
                              <HiOutlineClipboardCopy />{" "}
                            </span>{" "}
                            <span> 4 Reading Test Papers</span>
                          </p>
                        </div>
                        <hr />
                        <div className={Styles.Card_Menu}>
                          <div>
                            {" "}
                            <p>₹ 299.00 /-</p>
                            <p>₹ 299.00 /- </p>
                          </div>
                          <div>
                            {" "}
                            <button> Download Paper</button>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {TestPapers.map((el, i) => (
                    <div key={i + 78} className={Styles.card_design}>
                      <div className={`${Styles.Card1} ${
                          i % 2 === 0 ? Styles.Card1_Img : Styles.Card2_Img
                        }`}>
                        <div>
                          <button>Writing</button>{" "}
                        </div>
                      </div>
                      <div>
                        <div>
                          {/* <p>
                         Duration:
                         <span
                           style={{ color: "#fa763c", fontWeight: "600" }}
                         >
                           {" "}
                           9 Hrs 10 Min
                         </span>{" "}
                       </p> */}
                          <p style={{ fontSize: "19px", fontWeight: "600" }}>
                            Reading Writing Test <br /> Package 0023
                          </p>
                        </div>
                        <hr />
                        <div className={Styles.TestPackageDet}>
                          <p>
                            <strong>Test package Includes : </strong>
                          </p>
                          <p>
                            <span>
                              <PiVideo />
                            </span>
                            <span>1 Introducation Lesson </span>
                          </p>
                          <p>
                            {" "}
                            <span>
                              <IoNewspaperOutline />
                            </span>
                            <span> 1 Cheat Sheets</span>
                          </p>
                          <p>
                            <span>
                              <LuFileSpreadsheet />
                            </span>{" "}
                            4 Writing Test Papers
                          </p>
                          <p>
                            {" "}
                            <span>
                              <HiOutlineClipboardCopy />{" "}
                            </span>{" "}
                            <span> 4 Reading Test Papers</span>
                          </p>
                        </div>
                        <hr />
                        <div className={Styles.Card_Menu}>
                          <div>
                            {" "}
                            <p>₹ 299.00 /-</p>
                            <p>₹ 299.00 /- </p>
                          </div>
                          <div>
                            {" "}
                            <button> Download Paper</button>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <div className={Styles.Pagination}>
                    <div>
                      <hr />
                    </div>
                    <div>
                      <p className={Styles.Page}>
                        <BsChevronLeft />
                      </p>
                      <p className={Styles.active}>1</p>
                      <p className={Styles.Page}>2</p>
                      <p className={Styles.Page}>3</p>
                      <p className={Styles.Page}>4</p>
                      <p className={Styles.Page}>5</p>
                      <p className={Styles.Page}>
                        {" "}
                        <BsChevronRight />{" "}
                      </p>
                    </div>
                    <div>
                      <hr />
                    </div>
                  </div>
                  <div className={Styles.FAQ}>
                    <h1 className="Font_Rajdhani">
                      Frequently Asked Questions
                    </h1>
                    <div className={Styles.faq}>
                      <input id="faq-a" type="checkbox" />
                      <label for="faq-a">
                        <p className={Styles.faq_heading}>
                          <span>1</span>
                          {"  "} Can a Person extend their 482 visa ?
                        </p>
                        <div className={Styles.faq_arrow}></div>
                        <p className={Styles.faq_text}>
                          It is completely safe and totally legal! There will is
                          no record of this process to your shared Dropbox
                          users.
                        </p>
                      </label>
                      <input id="faq-b" type="checkbox" />
                      <label for="faq-b">
                        <p className={Styles.faq_heading}>
                          <span>2</span> Is a person holding a 482 visa eligible
                          to work in Australia ?
                        </p>
                        <div className={Styles.faq_arrow}></div>
                        <p className={Styles.faq_text}>
                          Does a Person need to have an employer to sponsor them
                          so as to apply for a TSS visa ?
                          <strong>
                            In order to control the risk and secure the space
                            you earned, we will gradually process it.
                          </strong>{" "}
                          during this time you can still use your account as
                          normal as usual.
                        </p>
                      </label>
                      <input id="faq-c" type="checkbox" />
                      <label for="faq-c">
                        <p className={Styles.faq_heading}>
                          <span>3</span> How does one apply for a TSS visa ?
                        </p>
                        <div className={Styles.faq_arrow}></div>
                        <p className={Styles.faq_text}>
                          NO ACCESS TO YOUR PERSONAL ACCOUNT OR INFO IS
                          REQUIRED! All I need from you is your Dropbox referral
                          link.
                        </p>
                      </label>
                    </div>{" "}
                    <div>
                      <hr />{" "}
                    </div>
                  </div>

                  <div className={Styles.BrowseService}>
                    <h1 className="Font_Rajdhani">Browse Other Services</h1>
                    <div>
                      <div>
                        <div>
                          <img
                            src="/assets/image/TestPapers.png"
                            alt="testPapers"
                          />
                        </div>
                        <div>Live Classes</div>
                        <div className={Styles.ArrowHover}>
                          <BsFillArrowRightCircleFill />{" "}
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            src="/assets/image/TestPapers.png"
                            alt="testPapers"
                          />
                        </div>
                        <div>Upcoming Mock Test</div>
                        <div className={Styles.ArrowHover}>
                          <BsFillArrowRightCircleFill />{" "}
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            src="/assets/image/TestPapers.png"
                            alt="testPapers"
                          />
                        </div>
                        <div>All Test papers</div>
                        <div className={Styles.ArrowHover}>
                          <BsFillArrowRightCircleFill />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Top Footer Section*/}
      <TopFooter />
      {/* Footer Section */}
      <Footer />
    </div>
  );
}
