import { IoIosArrowDropleftCircle } from "react-icons/io";
export default function SliderArrow1(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "black" }}
      onClick={onClick}>
      <IoIosArrowDropleftCircle />
    </div>
  );
}
