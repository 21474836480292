import {
  BOOK_A_DEMO_FAILED,
  BOOK_A_DEMO_REQ,
  BOOK_A_DEMO_RESET,
  BOOK_A_DEMO_SUCCESS,
} from "../Constant/BookDemoConstant";

export const Book_Demo_Reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case BOOK_A_DEMO_REQ: {
      return { loading: true };
    }

    case BOOK_A_DEMO_SUCCESS: {
      return { loading: false, success: payload };
    }

    case BOOK_A_DEMO_FAILED: {
      return { loading: false, error: payload };
    }

    case BOOK_A_DEMO_RESET: {
      return { laoding: false, success: "", error: "" };
    }
    default:
      return state;
  }
};
