import NavigationRoute from "./Routes/NavigationRoute";
import "./Css/style.css";
function App() {
  return (
    <div>
      {" "}
      <NavigationRoute />{" "}
    </div>
  );
}

export default App;
