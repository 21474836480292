import { MdModeEdit } from "react-icons/md";
import RightSidePanel from "../../Components/RightSidePanel";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Styles from "./Dashboard.module.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { PiVideo } from "react-icons/pi";
import { LuFileSpreadsheet } from "react-icons/lu";
import { HiOutlineAnnotation, HiOutlineClipboardCopy } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Package_List } from "../../Action/PacakgeAction";
import Loading from "../../Components/Loading";
import Enroll from "../Home/Enroll";
import Slider from "react-slick";
import PackageCard from "../../Components/PackageCard";
import Styles2 from "../Home/Home.module.css";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import { Take_Test_Det } from "../../Action/TLastAction";
import { TAKE_A_TEST_RESET } from "../../Constant/TLatConstant";
import { Test_Result } from "../../Action/QuestionTestAction";
export default function Dashboard() {
  const [Score, setScore] = useState("");
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  const Navigate = useNavigate();
  const Mobile_Screen = window.innerWidth;
  const { Login_User } = useSelector((state) => state.USER_LOGIN_REDUCER);
  const { loading, Test_Dets, error } = useSelector(
    (s) => s.Take_Test_Det_Reducer
  );
  const { TLatResult } = useSelector((s) => s.TestResultReducer);
  const [CourseId, setCourseId] = useState("");
  const [EnrollActive, setEnrollActive] = useState(false);
  const { loading: loadingPack, PackageRec } = useSelector(
    (s) => s.Package_List_Reducer
  );

  const dispatch = useDispatch();
  const BrowsePackageRef = useRef();

  const BrowsePacakgesettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow:
      Mobile_Screen <= 480
        ? 1
        : Mobile_Screen <= 780
        ? PackageRec?.length >= 2
          ? 2
          : 1
        : PackageRec?.length >= 2
        ? 2
        : PackageRec?.length == 2
        ? 2
        : 1,
    slidesToScroll: 1,
  };

  const get_Packages = useCallback(() => {
    dispatch(Package_List({ page_size: 10, page_no: 1 },"MainPackages"));
  }, [dispatch]);

  useEffect(() => {
    get_Packages();
  }, [get_Packages]);

  useMemo(() => {
    dispatch(Test_Result());
    const PsychometricTestScore = localStorage.getItem("PsychometricTestScore");
    if (PsychometricTestScore) {
      setScore(PsychometricTestScore);
    }
  }, [dispatch]);

  useEffect(() => {
    if (Test_Dets) {
      Navigate(`/T-LAT-acknowledge/${Test_Dets}`);
      dispatch({ type: TAKE_A_TEST_RESET });
    }

    if (error) {
      Navigate("/error", { state: { error } });
    }
  }, [Test_Dets, error]);

  return (
    <div className={Styles.Dashboard}>
      {/* SidePanel */}
      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Dashboard"
        />
      ) : (
        ""
      )}

      {loadingPack || loading ? <Loading /> : ""}

      {EnrollActive ? (
        <Enroll
          setEnrollActive={setEnrollActive}
          Package_Id={EnrollActive}
          courseId={CourseId}
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Dashboard"
        />
      ) : (
        ""
      )}

      {/* Middle Section */}

      {!SidebarOpen ? (
        <section className={Styles.MiddleSection}>
          <div style={{ marginTop: "30px" }}>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              PageName={"Dashbaord"}
            />
          </div>
          <div className={Styles.Dashboard_Profile}>
            <div>
              <img
                src="/assets/image/DashboardBanner.jpg"
                alt="profile_banner"
              />
              <div className={Styles.Profile_details}>
                <div>
                  <div>
                    <img src="/assets/image/user.svg" alt="profile" />
                  </div>
                  <div>
                    <h4>
                      {Login_User?.First_Name
                        ? Login_User?.First_Name +
                          " " +
                          (Login_User?.Last_Name ? Login_User?.Last_Name : "")
                        : ""}
                    </h4>
                    {/* <p>Joined at Dec 2023</p> */}
                  </div>
                </div>
              </div>
              {window.innerWidth <= 480 ? (
                ""
              ) : (
                <div className={Styles.Profile_edit}>
                  <div>
                    <button>
                      <span>
                        <MdModeEdit />
                      </span>
                      <span>Edit Profile </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={Styles.Predicated_Score_Container}>
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: window.innerWidth <= 480 ? "column" : "row",
              }}
              className={Styles.Test}
            >
              <h3>T-LAT : {TLatResult} </h3>

              <div>
                {TLatResult || TLatResult === 0 ? (
                  <button
                    onClick={() => Navigate("/T-LAT-answers")}
                    className={Styles.custom_btn}
                  >
                    Check your Result
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      dispatch(
                        Take_Test_Det({
                          EmailAddress: Login_User?.EmailAddress,
                        })
                      )
                    }
                    className={Styles.custom_btn}
                  >
                    Take A Test
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className={Styles.Predicated_Score_Container2}>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ margin: "0", padding: "0" }}>
                {" "}
                <h4>Study Interested :</h4>{" "}
              </div>
              <div>
                {" "}
                <select
                  style={{
                    padding: "4px 20px",
                    color: "blue",
                    border: "0.1px solid rgb(229, 229, 229)",
                  }}
                  valaue="IELTS"
                >
                  <option value="IELTS">IELTS</option>
                </select>
              </div>
            </div>
          </div>
          <div className={Styles.Predicated_Score_Container}>
            <h4>Predicted Score :</h4>
            <table>
              {window.innerWidth <= 600 ? (
                <tbody>
                  {/* <tr>
                  <td colSpan={4}>Predicated Score : -</td>
                </tr> */}
                  <tr>
                    <td>Writing</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Speaking</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Listening</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Overall Score</td>
                    <td>0</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>Writing</td>
                    <td>Reading</td>
                    <td>Speaking</td>
                    <td>Listening</td>
                    <td>Overall Score</td>
                  </tr>
                  <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td> <td>0</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div className={Styles.Predicated_Score_Container}>
            <h4>Target Score :</h4>
            <table>
              {window.innerWidth <= 600 ? (
                <tbody>
                  <tr>
                    <td>Writing</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Speaking</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Listening</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Overall Score</td>
                    <td>0</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {/* <tr>
                  <td colSpan={4}>Predicated Score : -</td>
                </tr> */}
                  <tr>
                    <td>Writing</td>
                    <td>Reading</td>
                    <td>Speaking</td>
                    <td>Listening</td>
                    <td>Overall Score</td>
                  </tr>
                  <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td> <td>0</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div className={Styles.Predicated_Score_Container}>
            <h4>Session IELTS Score :</h4>
            <table>
              {window.innerWidth <= 600 ? (
                <tbody>
                  {/* <tr>
                  <td colSpan={4}>Predicated Score : -</td>
                </tr> */}
                  <tr>
                    <td>Writing</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Speaking</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Listening</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Overall Score</td>
                    <td>0</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {/* <tr>
                  <td colSpan={4}>Predicated Score : -</td>
                </tr> */}
                  <tr>
                    <td>Writing</td>
                    <td>Reading</td>
                    <td>Speaking</td>
                    <td>Listening</td>
                    <td>Overall Score</td>
                  </tr>
                  <tr>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td> <td>0</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          {/* <div className={Styles.Dashboard_Profile}>
            <div>
              <img
                src="/assets/image/dashboard_top_banner.png"
                alt="profile_banner"
              />
              <div className={Styles.Profile_details}>
                <div>
                  <div>
                    <img src="/assets/image/user.avif" alt="profile" />
                  </div>
                  <div>
                    <h4>Suresh Sharma</h4>
                    <p>Joined at Dec 2023</p>
                  </div>
                </div>
              </div>
              <div className={Styles.Profile_edit}>
                <div>
                  <button>
                    <span>
                      <MdModeEdit />
                    </span>
                    <span>Edit Profile </span>
                  </button>
                </div>
              </div>
            </div>
          </div> */}

          <section style={{ background: "#f4f9ff", paddingBottom: "60px" }}>
            <div style={{ margin: "30px" }}>
              <div className={Styles.Browse_Package_Section}>
                <div>
                  {" "}
                  <h1 className={Styles.Heading}>
                    <span className="Font_Rajdhani">Browse Courses </span>
                  </h1>
                  {/* <p>
                    In this free interactive webinar, our IELTS expert will help
                    us be ready to answer
                    <br /> a range of common questions types for Part 1 of the
                    Speaking Test.
                  </p>{" "} */}
                </div>
                <div className={Styles.Browse_Package_Scroller}>
                  <div onClick={() => BrowsePackageRef?.current?.slickPrev()}>
                    <FaCircleArrowLeft />
                  </div>
                  <div onClick={() => BrowsePackageRef?.current?.slickNext()}>
                    {" "}
                    <FaCircleArrowRight />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ margin: "0 30px" }}>
              <Slider
                ref={BrowsePackageRef}
                style={{ width: "100%" }}
                {...BrowsePacakgesettings}
              >
                {PackageRec?.map((el, i) => (
                  <div key={el._id}>
                    <PackageCard
                      setEnrollActive={setEnrollActive}
                      EnrollActive={EnrollActive}
                      setCourseId={setCourseId}
                      el={el}
                      Styles={Styles2}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </section>

          {/* <div className={Styles.WritingPackage}>
            <div>
              <div>
                <h1>Writing packages</h1>
                <p>View All</p>
              </div>
              <div className={Styles.WritingCardContainer}>
                {WritingCard.map((el, i) => (
                  <div key={i + 34} className={Styles.WritingCard}>
                    <div>
                      <img src="/assets/image/Offline.jpg" alt="writing card" />
                      <p>Writing</p>
                    </div>
                    <div>
                      <div>
                        <p>Duration: 9 Hrs 30Min</p>
                        <h4>
                          Speaking & Writing <br /> Intermediate Package 003
                        </h4>
                      </div>
                      <hr />
                      <div>
                        <p>This Package includes :</p>
                        <div className={Styles.SpeakingWriting}>
                          <p>
                            <span>
                              <PiVideo />
                            </span>
                            <span>6 Test Papers </span>
                          </p>
                          <p>
                            <span>
                              <LuFileSpreadsheet />
                            </span>
                            <span>2 Live mock Test </span>
                          </p>
                          <p>
                            <span>
                              <HiOutlineAnnotation />
                            </span>
                            <span> 2 Counselling Session</span>
                          </p>
                          <p>
                            <span>
                              <IoNewspaperOutline />
                            </span>
                            <span>4 Cheat Sheets </span>
                          </p>
                          <p>
                            <span>
                              <HiOutlineClipboardCopy />{" "}
                            </span>{" "}
                            <span> 12 Lessons</span>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className={Styles.BottomWritingCard}>
                        <div>
                          <p>₹ 299 /-</p>
                          <p>₹ 299 /-</p>
                        </div>
                        <div>
                          <button>Buy Now</button>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={Styles.WritingPackage}>
            <div>
              <div>
                <h1>Reading packages</h1>
                <p>View All</p>
              </div>
              <div className={Styles.WritingCardContainer}>
                {WritingCard.map((el, i) => (
                  <div key={i + 34} className={Styles.WritingCard}>
                    <div>
                      <img src="/assets/image/Online.jpg" alt="writing card" />
                      <p>Writing</p>
                    </div>

                    <div>
                      {" "}
                      <div>
                        <p>Duration: 9 Hrs 30Min</p>
                        <h4>
                          Speaking & Writing <br /> Intermediate Package 003
                        </h4>
                      </div>
                      <hr />
                      <div>
                        <p>This Package includes :</p>
                        <div className={Styles.SpeakingWriting}>
                          <p>
                            <span>
                              <PiVideo />
                            </span>
                            <span>6 Test Papers </span>
                          </p>
                          <p>
                            <span>
                              <LuFileSpreadsheet />
                            </span>
                            <span>2 Live mock Test </span>
                          </p>
                          <p>
                            <span>
                              <HiOutlineAnnotation />
                            </span>
                            <span> 2 Counselling Session</span>
                          </p>
                          <p>
                            <span>
                              <IoNewspaperOutline />
                            </span>
                            <span>4 Cheat Sheets </span>
                          </p>
                          <p>
                            <span>
                              <HiOutlineClipboardCopy />{" "}
                            </span>{" "}
                            <span> 12 Lessons</span>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className={Styles.BottomWritingCard}>
                        <div>
                          <p>₹ 299 /-</p>
                          <p>₹ 299 /-</p>
                        </div>
                        <div>
                          <button>Buy Now</button>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={Styles.WritingPackage}>
            <div>
              <div>
                <h1>Speaking packages</h1>
                <p>View All</p>
              </div>
              <div className={Styles.WritingCardContainer}>
                {WritingCard.map((el, i) => (
                  <div key={i + 34} className={Styles.WritingCard}>
                    <div>
                      <img src="/assets/image/Offline.jpg" alt="writing card" />
                      <p>Writing</p>
                    </div>

                    <div>
                      {" "}
                      <div>
                        <p>Duration: 9 Hrs 30Min</p>
                        <h4>
                          Speaking & Writing <br /> Intermediate Package 003
                        </h4>
                      </div>
                      <hr />
                      <div>
                        <p>This Package includes :</p>
                        <div className={Styles.SpeakingWriting}>
                          <p>
                            <span>
                              <PiVideo />
                            </span>
                            <span>6 Test Papers </span>
                          </p>
                          <p>
                            <span>
                              <LuFileSpreadsheet />
                            </span>
                            <span>2 Live mock Test </span>
                          </p>
                          <p>
                            <span>
                              <HiOutlineAnnotation />
                            </span>
                            <span> 2 Counselling Session</span>
                          </p>
                          <p>
                            <span>
                              <IoNewspaperOutline />
                            </span>
                            <span>4 Cheat Sheets </span>
                          </p>
                          <p>
                            <span>
                              <HiOutlineClipboardCopy />{" "}
                            </span>{" "}
                            <span> 12 Lessons</span>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className={Styles.BottomWritingCard}>
                        <div>
                          <p>₹ 299 /-</p>
                          <p>₹ 299 /-</p>
                        </div>
                        <div>
                          <button>Buy Now</button>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={Styles.WritingPackage}>
            <div>
              <div>
                <h1>Our Most Selling Packages</h1>
                <p>View All</p>
              </div>
              <div className={Styles.WritingCardContainer}>
                {WritingCard.map((el, i) => (
                  <div key={i + 34} className={Styles.WritingCard}>
                    <div>
                      <img
                        src="/assets/image/Crash-course.jpg"
                        alt="writing card"
                      />
                      <p>Writing</p>
                    </div>

                    <div>
                      {" "}
                      <div>
                        <p>Duration: 9 Hrs 30Min</p>
                        <h4>
                          Speaking & Writing <br /> Intermediate Package 003
                        </h4>
                      </div>
                      <hr />
                      <div>
                        <p>This Package includes :</p>
                        <div className={Styles.SpeakingWriting}>
                          <p>
                            <span>
                              <PiVideo />
                            </span>
                            <span>6 Test Papers </span>
                          </p>
                          <p>
                            <span>
                              <LuFileSpreadsheet />
                            </span>
                            <span>2 Live mock Test </span>
                          </p>
                          <p>
                            <span>
                              <HiOutlineAnnotation />
                            </span>
                            <span> 2 Counselling Session</span>
                          </p>
                          <p>
                            <span>
                              <IoNewspaperOutline />
                            </span>
                            <span>4 Cheat Sheets </span>
                          </p>
                          <p>
                            <span>
                              <HiOutlineClipboardCopy />{" "}
                            </span>{" "}
                            <span> 12 Lessons</span>
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className={Styles.BottomWritingCard}>
                        <div>
                          <p>₹ 299 /-</p>
                          <p>₹ 299 /-</p>
                        </div>
                        <div>
                          <button>Buy Now</button>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={Styles.WritingPackage}>
            <div>
              <div>
                <h1>Evaluate Your Career</h1>
                <p></p>
              </div>
              <div className={Styles.WritingCardContainer}>
                <div className={Styles.MockTest}>
                  <div>
                    <img src="/assets/image/TestPapers.png" alt="icon" />
                    <p>
                      Mock <br /> Test
                    </p>
                  </div>
                  <div>
                    <span>
                      <BsFillArrowRightCircleFill />
                    </span>
                  </div>
                </div>
                <div className={Styles.MockTest1}>
                  <div>
                    <img src="/assets/image/TestPapers.png" alt="icon" />
                    <p>
                      Live <br /> Classes
                    </p>
                  </div>
                  <div>
                    <span>
                      <BsFillArrowRightCircleFill />
                    </span>
                  </div>
                </div>
                <div className={Styles.MockTest2}>
                  <div>
                    <img src="/assets/image/TestPapers.png" alt="icon" />
                    <p>
                      Course <br /> Material
                    </p>
                  </div>
                  <div>
                    <span>
                      <BsFillArrowRightCircleFill />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      ) : (
        ""
      )}
      {/* Middle Section */}
      {Mobile_Screen >= 780 ? <RightSidePanel /> : ""}

      {/* Right Section  */}
    </div>
  );
}
