import { useNavigate } from "react-router-dom";
import Styles from "./Navbar.module.css";

export default function TopFooter() {
  const Navigate = useNavigate();
  return (
    <section className={Styles.Footer_Top}>
      <div>
        <div>
          <h1 className={"Font_Rajdhani"}>
            Start Your IELTS Journey With The Best
          </h1>
        </div>
        <div>
          <button  className={"Font_Rajdhani"} onClick={() => Navigate("/signup")}>Create Account</button>
          <button  className={"Font_Rajdhani"} onClick={() => Navigate("/login")}>Login Now</button>
        </div>
      </div>
    </section>
  );
}
