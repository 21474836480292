import {
  QUESTION_RECORD_LIST_FAILED,
  QUESTION_RECORD_LIST_REQ,
  QUESTION_RECORD_LIST_RESET,
  QUESTION_RECORD_LIST_SUCCESS,
  SUBMIT_QUESTION_RECORD_FAILED,
  SUBMIT_QUESTION_RECORD_REQ,
  SUBMIT_QUESTION_RECORD_RESET,
  SUBMIT_QUESTION_RECORD_SUCCESS,
  TLAT_ANSWER_RESULT_FAILED,
  TLAT_ANSWER_RESULT_REQ,
  TLAT_ANSWER_RESULT_RESET,
  TLAT_ANSWER_RESULT_SUCCESS,
  TLAT_TEST_RESULT_FAILED,
  TLAT_TEST_RESULT_REQ,
  TLAT_TEST_RESULT_RESET,
  TLAT_TEST_RESULT_SUCCESS,
} from "../Constant/QuestionTestConstant";

export const QuestionListResucer = (state = {}, { type, payload }) => {
  switch (type) {
    case QUESTION_RECORD_LIST_REQ: {
      return { loading: true };
    }

    case QUESTION_RECORD_LIST_SUCCESS: {
      return { Questiondata: payload, loading: false };
    }

    case QUESTION_RECORD_LIST_FAILED: {
      return { error: payload, loading: false };
    }

    case QUESTION_RECORD_LIST_RESET: {
      return {};
    }
    default:
      return state;
  }
};

export const QuestionRecordSubmitReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SUBMIT_QUESTION_RECORD_REQ: {
      return { loading: true };
    }

    case SUBMIT_QUESTION_RECORD_SUCCESS: {
      return { success: payload, loading: false };
    }

    case SUBMIT_QUESTION_RECORD_FAILED: {
      return { error: payload, loading: false };
    }

    case SUBMIT_QUESTION_RECORD_RESET: {
      return {};
    }
    default:
      return state;
  }
};

export const TestResultReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TLAT_TEST_RESULT_REQ: {
      return { loading: true };
    }

    case TLAT_TEST_RESULT_SUCCESS: {
      return { loading: false, TLatResult: payload };
    }

    case TLAT_TEST_RESULT_FAILED: {
      return { loading: false, error: payload };
    }

    case TLAT_TEST_RESULT_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const TestAnswerDetailsReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case TLAT_ANSWER_RESULT_REQ: {
      return { loading: true };
    }

    case TLAT_ANSWER_RESULT_SUCCESS: {
      return { loading: false, AnsweDet: payload };
    }

    case TLAT_ANSWER_RESULT_FAILED: {
      return { loading: false, error: payload };
    }

    case TLAT_ANSWER_RESULT_RESET: {
      return { loading: false, error: "" };
    }

    default:
      return state;
  }
};
