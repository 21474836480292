import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listClassCenter } from "../../Action/classCenterAction";

const ClassCenterDropDown = React.memo(() => {
  const { classCenterRecs } = useSelector((s) => s.classCenterListReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listClassCenter());
  }, [dispatch]);

  return (
    <>
      {classCenterRecs ? (
        classCenterRecs?.map((ev, i) => (
          <option key={i + 900} value={ev?._id}>
            {ev?.classCenter_Name}
          </option>
        ))
      ) : (
        <></>
      )}
    </>
  );
});

export default ClassCenterDropDown;
