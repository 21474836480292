import Styles from "./SidePanel.module.css";

export default function RightSidePanel({ Page }) {
  return (
    <div className={Styles.MainContainer}>
      <div className={Styles.RightSidePanel}>
        <div className={Styles.Coordinator}>
          <div className={Styles.Coordinator_top}>
            <div>
              <img src="/assets/image/user.svg" alt="profile" />
            </div>
            <div>
              <p>Coordinator</p>
              <h5>Neha Sagar</h5>
            </div>
          </div>
          <hr />
          <div>
            <p>info@testifyi.com</p>
          </div>
          <hr />
          <div>
            <p>+91 9310410737</p>
          </div>
        </div>
        <div className={Styles.Coordinator}>
          <div className={Styles.Coordinator_top}>
            <div>
              <img src="/assets/image/user.svg" alt="profile" />
            </div>
            <div>
              <p className={Styles.EducationTrainer}>Trainer</p>
              <h5>Chitranshi Rastogi</h5>
            </div>
          </div>
          <hr />
          <div>
            <p>info@testifyi.com</p>
          </div>
          <hr />
          <div>
            <p>+91 8851767054</p>
          </div>
        </div>
        <div className={Styles.Coordinator}>
          <div className={Styles.Coordinator_top}>
            <div>
              <img src="/assets/image/user.svg" alt="profile" />
            </div>
            <div>
              <p className={Styles.EducationCoordinator}>Manager</p>
              <h5>Vivek Sharma</h5>
            </div>
          </div>
          <hr />
          <div>
            <p>info@testifyi.com</p>
          </div>
          <hr />
          <div>
            <p>+91 7982476900</p>
          </div>
        </div>
      </div>
    </div>
  );
}
