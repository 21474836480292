import {
  CLASS_MODE_LIST_FAILED,
  CLASS_MODE_LIST_REQ,
  CLASS_MODE_LIST_SUCCESS,
  CLASS_TIMING_LIST_FAILED,
  CLASS_TIMING_LIST_REQ,
  CLASS_TIMING_LIST_SUCCESS,
} from "../Constant/CommonEnumsConstant";

export const listClassTimingReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CLASS_TIMING_LIST_REQ: {
      return { loading: true };
    }

    case CLASS_TIMING_LIST_SUCCESS: {
      return { loading: false, classTimingRec: payload };
    }

    case CLASS_TIMING_LIST_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};

export const listCalssModeReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CLASS_MODE_LIST_REQ: {
      return { loading: true };
    }

    case CLASS_MODE_LIST_SUCCESS: {
      return { loading: false, ClassModeRec: payload };
    }

    case CLASS_MODE_LIST_FAILED: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};
