import { useDispatch, useSelector } from "react-redux";
import Footer from "../../Components/Footer";
import Navbar2 from "../../Components/Navbar2";
import { useCallback, useEffect } from "react";
import { packageDetails } from "../../Action/PacakgeAction";
import Loading from "../../Components/Loading";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { orderStatus, RetryPayment } from "../../Action/OrderPackageAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function Order() {
  const { loading, DetailsPack, error } = useSelector(
    (s) => s.packageDetailsReducer
  );
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const OrderTrasactionStatus = query.get("resp");
  const { OrderDet } = useSelector((s) => s.orderStatusReducer);
  const Navigate = useNavigate();
  const {
    loading: loadingOrder,
    error: errorOrder,
    OrderDet: OrderRetry,
  } = useSelector((s) => s.PackageOrderReducer);
  const { OrderId } = useParams();
  const { Login_User } = useSelector((state) => state.USER_LOGIN_REDUCER);

  const dispatch = useDispatch();

  const Get_Order_Det = useCallback(() => {
    if (OrderId) {
      dispatch(orderStatus(OrderId));
    }
  }, [dispatch, OrderId]);

  const handlePlaceOder = () => {
    if (Login_User) {
      dispatch(
        RetryPayment({
          OrderId: OrderDet?._id,
          EmailAddress: Login_User?.EmailAddress,
        })
      );
    } else {
      Navigate("/login");
    }
  };

  useEffect(() => {
    if (OrderDet?.PackageId) {
      dispatch(packageDetails(OrderDet?.PackageId));
    }
  }, [OrderDet, dispatch]);

  useEffect(() => {
    Get_Order_Det();
  }, [Get_Order_Det]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (OrderRetry) {
      window.location.href = OrderRetry;
    }
  }, [OrderRetry]);

  return (
    <>
      <Navbar2 />
      {loading || loadingOrder ? <Loading /> : ""}
      <section className="CartMargin">
        <div className="Cart_section">
          <div className="CartSection1">
            <div>
              <h4> ORDER DETAILS </h4>
            </div>
            {OrderTrasactionStatus ? (
              <div>
                {OrderTrasactionStatus == "success" ? (
                  <h4 style={{ color: "green" }}>
                    Package Order Successfully !
                  </h4>
                ) : (
                  <h4 style={{ color: "red" }}>Package Order Failed !</h4>
                )}
                <p>
                  For More Details <button className="custom_btn">Login</button>
                </p>
              </div>
            ) : (
              <>
                {" "}
                {DetailsPack ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4>{DetailsPack?.PackageId?.Name}</h4>
                      {/* <div>
                    <button onClick={() => handleRemove()} className="rmv_btn">
                      REMOVE
                    </button>{" "}
                  </div> */}
                    </div>

                    <p
                      style={{
                        fontSize: "14px",
                        margin: "0",
                        padding: "2px 0 4px 0",
                      }}
                    >
                      {DetailsPack?.PackageId?.Detail}
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        margin: "0",
                        padding: "2px 0",
                        fontWeight: "600",
                      }}
                    >
                      Duration: {DetailsPack?.PackageId?.Duration} Weeks
                    </p>
                    <hr />
                    <div>
                      <p>
                        <strong>This package Includes : </strong>
                      </p>
                      <div className={"SpeakingWriting"}>
                        {DetailsPack?.LIVE_CLASSES ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_LIVE_CLASSES.svg"
                                alt="icon_LIVE_CLASSES"
                              />
                            </span>
                            <span>
                              {DetailsPack?.LIVE_CLASSES} LIVE CLASSES{" "}
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.One_On_One_DOUBT_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                                alt="icon_LIVE_CLASSES"
                              />
                            </span>
                            <span>1:1 DOUBT SESSIONS </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.DOUBT_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                                alt="icon_LIVE_CLASSES"
                              />
                            </span>
                            <span> DOUBT SESSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}
                        {DetailsPack?.SESSION_TESTS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_SESSION_TESTS.svg"
                                alt="icon_SESSION_TESTS"
                              />
                            </span>
                            <span>SESSION TESTS </span>
                          </p>
                        ) : (
                          ""
                        )}
                        {DetailsPack?.STUDY_MATERIAL_ACCESS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_STUDY_MATERIAL_ACCESS.svg"
                                alt="icon_STUDY_MATERIAL_ACCESS"
                              />
                            </span>
                            <span> STUDY MATERIAL ACCESS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.COMPLETE_MOCK_TEST ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_MOCK_TEST.svg"
                                alt="icon_MOCK_TEST"
                              />
                            </span>
                            <span>
                              {DetailsPack?.COMPLETE_MOCK_TEST} COMPLETE MOCK
                              TEST
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.COMPLETE_MOCK_TEST_AI ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_MOCK_TEST_AI.svg"
                                alt="icon_MOCK_TEST"
                              />
                            </span>
                            <span>
                              {DetailsPack?.COMPLETE_MOCK_TEST_AI} COMPLETE MOCK
                              TEST (POWERED BY AI)
                            </span>
                          </p>
                        ) : (
                          ""
                        )}
                        {DetailsPack?.TESTIFYI_REALITY_TEST ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_reality_test.svg"
                                alt="icon_reality_test"
                              />
                            </span>
                            <span>
                              {" "}
                              {DetailsPack?.TESTIFYI_REALITY_TEST} TESTIFYI
                              REALITY TEST
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.MASTER_CLASS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_Master_class.svg"
                                alt="icon_Master_class"
                              />
                            </span>
                            <span> MASTER CLASS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.One_On_One_SPEAKING_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_Speaking_Session.svg"
                                alt="icon_Speaking_Session"
                              />
                            </span>
                            <span>1:1 SPEAKING SESSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.LANGUAGE_PRACTICE_TEST ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_LANGUAGE_PRACTICE_TEST.svg"
                                alt="icon_LANGUAGE_PRACTICE_TEST"
                              />
                            </span>
                            <span>
                              {DetailsPack?.LANGUAGE_PRACTICE_TEST} TESTIFYI
                              PRACTICE TEST
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.GRAMMAR_TEST === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_GRAMMAR_TEST.svg"
                                alt="icon_GRAMMAR_TEST"
                              />
                            </span>
                            <span>GRAMMAR TEST</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.MOCK_INTERVIEW === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_Mock_INTERVIEW.svg"
                                alt="icon_Mock_INTERVIEW"
                              />
                            </span>
                            <span>MOCK INTERVIEW</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.GROUP_DISCUSSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_GROUP_DISCUSSIONS.svg"
                                alt="icon_GROUP_DISCUSSIONS"
                              />
                            </span>
                            <span>GROUP DISCUSSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.BUSINESS_ENGLISH_SESSIONS === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_BUSINESS_ENGLISH_SESSIONS.svg"
                                alt="icon_BUSINESS_ENGLISH_SESSIONS"
                              />
                            </span>
                            <span>BUSINESS ENGLISH SESSIONS</span>
                          </p>
                        ) : (
                          ""
                        )}

                        {DetailsPack?.SOFT_SKILLS_SESSION === "Yes" ? (
                          <p>
                            <span>
                              <img
                                src="/assets/image/ProductCard-Icon/icon_SOFT_SKILLS_SESSION.svg"
                                alt="icon_SOFT_SKILLS_SESSION"
                              />
                            </span>
                            <span>SOFT SKILLS SESSION</span>
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h4>Empty Cart</h4>
                    {/* <p>
                  search courses  <button className="custom_btn"></button>
                </p> */}
                  </div>
                )}
              </>
            )}
          </div>

          {OrderTrasactionStatus ? (
            ""
          ) : (
            <div>
              <div className="Price_Det_Cart">
                <div>
                  <h4>PRICE DETAILS</h4>
                </div>
                {DetailsPack ? (
                  DetailsPack?.PackageId?.DiscountedPrice ? (
                    <div>
                      <div className="Prices">
                        <p>Price</p>
                        <p>₹ {DetailsPack?.PackageId?.ActualCost}</p>
                      </div>
                      <div className="Prices">
                        <p> Discount for you</p>
                        <p style={{ color: "green" }}>
                          -
                          {+DetailsPack?.PackageId?.ActualCost -
                            +DetailsPack?.PackageId?.DiscountedPrice}
                        </p>
                      </div>
                      <div>
                        <hr className="dotted" />
                      </div>

                      <div className="Prices">
                        <h5>Total Amount</h5>
                        <h5>₹ {DetailsPack?.PackageId?.DiscountedPrice}</h5>
                      </div>

                      <div>
                        <hr className="dotted" />
                      </div>

                      <div>
                        <h6 style={{ color: "green" }}>
                          You will save{" "}
                          {+DetailsPack?.PackageId?.ActualCost -
                            +DetailsPack?.PackageId?.DiscountedPrice}{" "}
                          on this order.{" "}
                        </h6>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="Prices">
                        <p>Price</p>
                        <p>₹ {DetailsPack?.PackageId?.ActualCost}</p>
                      </div>
                      <div className="Prices">
                        <p> Discount for you</p>
                        <p style={{ color: "green" }}>- 0</p>
                      </div>
                      <div>
                        <hr className="dotted" />
                      </div>

                      <div className="Prices">
                        <h5>Total Amount</h5>
                        <h5>₹ {DetailsPack?.PackageId?.ActualCost}</h5>
                      </div>

                      <div>
                        <hr className="dotted" />
                      </div>

                      <div>
                        {/* <h6 style={{ color: "green" }}>
                      You will save{" "}
                      {+DetailsPack?.PackageId?.ActualCost -
                        +DetailsPack?.PackageId?.DiscountedPrice}{" "}
                      on this order.{" "}
                    </h6> */}
                      </div>
                    </div>
                  )
                ) : (
                  <div>
                    <div className="Prices">
                      <p>Price</p>
                      <p>₹ 0</p>
                    </div>
                    <div className="Prices">
                      <p> Discount for you</p>
                      <p style={{ color: "green" }}>- 0</p>
                    </div>
                    <div>
                      <hr className="dotted" />
                    </div>

                    <div className="Prices">
                      <h5>Total Amount</h5>
                      <h5>₹ 0</h5>
                    </div>

                    <div>
                      <hr className="dotted" />
                    </div>

                    <div>
                      {/* <h6 style={{ color: "green" }}>
                  You will save{" "}
                  {+DetailsPack?.PackageId?.ActualCost -
                    +DetailsPack?.PackageId?.DiscountedPrice}{" "}
                  on this order.{" "}
                </h6> */}
                    </div>
                  </div>
                )}
                <div className="PlaceOrder">
                  {OrderDet?.transactionStatus === "Pending" ||
                  OrderDet?.transactionStatus === "Failed" ? (
                    <button
                      disabled={!DetailsPack}
                      onClick={() => handlePlaceOder()}
                      className="custom_btn"
                    >
                      RETRY PAYMENT
                    </button>
                  ) : (
                    <button
                      disabled={!DetailsPack}
                      // onClick={() => handlePlaceOder()}
                      className="custom_btn"
                    >
                      ORDER SUCCESS
                    </button>
                  )}
                </div>
              </div>

              {errorOrder ? (
                <p style={{ color: "red", textAlign: "center" }}>
                  {errorOrder}
                </p>
              ) : (
                ""
              )}

              <div className="Safe_Secure">
                <p>
                  <span>
                    <AiFillSafetyCertificate />
                  </span>
                  <span>Safe and Secure Payments 100%.</span>
                </p>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}
