import { IoIosCloseCircle } from "react-icons/io";
import "../Home/BookADemo.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ORDER_PACKAGE_RESET } from "../../Constant/OrderPackageConstant";

export default function TermsAndCondition({ setTermsVisible, setAcceptTerms }) {
  const dispatch = useDispatch();
  return (
    <>
      <div
        id="CreatContact"
        style={{ width: "100%" }}
        className={"overlay-note middle-hand w100 BookDemo"}
      >
        <div className="container" id="booking-container">
          <h1>TERMS AND CONDITIONS</h1>
          <div
            onClick={() => {
              setTermsVisible(false);
            }}
            className="closeBtn_Book"
          >
            <span>
              <IoIosCloseCircle />
            </span>
          </div>
          <div
            id="appointment-form"
            // onSubmit={handleSubmitDemo}
            style={{ height: "65vh", overflowY: "scroll" }}
          >
            <ol
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                fontSize: "14px",
              }}
            >
              <li>
                To enroll for the coaching classes you have to submit a copy of
                your ID proof.
              </li>
              <li>
                If you have paid some partial amount of your course fee in order
                to book your slot for enrollment then you have to pay the
                remaining due amount within three days of your course start
                date. Failing which the organization holds the right to freeze
                the classes or even cancel the enrollment. Note: There is no
                provision of refund in case of cancellation of enrollment or if
                you wish to discontinue the classes at any point after the
                booking.
              </li>
              <li>
                The study material and access to our exclusive training Portal
                will only be granted after the full payment of the package
                (course) is made.
              </li>
              <li>
                It is mandatory for the online students to keep their camera
                switched on and be visible during the class in order to enhance
                teaching and better understanding.
              </li>
              <li>
                The student cannot request for the change of timings or change
                of instructor once the course has started, no such requests
                shall be entertained. It can be taken under consideration only
                under special circumstances, that too subject to availability.
              </li>
              <li>
                If a student is unable to attend the classes for more than three
                days(up to 12 days) because of some genuine reason, a mail is
                required to be sent from the registered email ID stating the
                request to freeze the classes along with the reason - The
                decision rights stays with the organization.
              </li>
              <li>
                The Organization holds the right to change the trainers at any
                point of time in case of any inevitable circumstances.
              </li>
              <li>
                The student is responsible for any damage to the
                equipment/gadgets issued to him for use during the class and not
                allowed to carry the same out of the class.
              </li>
              <li>
                The organization holds the right to cancel the enrollment with
                immediate effect in case of inappropriate behavior/code of
                conduct or any violence.
              </li>
              <li>
                The Organization holds the right to cancel the enrollment with
                immediate effect if any student is found under
                influence/possession of any controlled substances/Alcohol in the
                premises and report to the concerned authorities.
              </li>
              <li>
                By accepting the terms & conditions, you are accepting the
                companies{" "}
                <Link
                  to="/terms-and-conditions"
                  style={{ color: "blue" }}
                  target="_blank"
                >
                  {" "}
                  terms and conditions
                </Link>{" "}
                also.
              </li>
            </ol>

            <div className="Book_A_demo_btn">
              <button
                onClick={() => {
                  setAcceptTerms(true);
                  setTermsVisible(false);
                  dispatch({ type: ORDER_PACKAGE_RESET });
                }}
                type="submit"
              >
                {"I agree"}
              </button>
            </div>
          </div>
          <div id="message"></div>
        </div>
      </div>
    </>
  );
}
