import RightSidePanel from "../../Components/RightSidePanel";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Styles from "./Material.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { Package_List } from "../../Action/PacakgeAction";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import PackageCard from "../../Components/PackageCard";
import Styles2 from "../Home/Home.module.css";
import Enroll from "../Home/Enroll";
import Loading from "../../Components/Loading";
export default function Materilas() {
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  const Mobile_Screen = window.innerWidth;
  const [CourseId, setCourseId] = useState("");
  const [EnrollActive, setEnrollActive] = useState(false);
  const { loading: loadingPack, PackageRec } = useSelector(
    (s) => s.Package_List_Reducer
  );
  const dispatch = useDispatch();
  const BrowsePackageRef = useRef();
  const BrowsePacakgesettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 600,
    slidesToShow:
      Mobile_Screen <= 480
        ? 1
        : Mobile_Screen <= 780
        ? PackageRec?.length >= 2
          ? 2
          : 1
        : PackageRec?.length >= 2
        ? 2
        : PackageRec?.length == 2
        ? 2
        : 1,
    slidesToScroll: 1,
  };
  const get_Packages = useCallback(() => {
    dispatch(Package_List({ page_size: 10, page_no: 1 },"MainPackages"));
  }, [dispatch]);

  useEffect(() => {
    get_Packages();
  }, [get_Packages]);
  return (
    <div className={Styles.Dashboard}>
      {/* SidePanel */}

      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Materials"
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Materials"
        />
      ) : (
        ""
      )}

      {loadingPack ? <Loading /> : ""}

      {EnrollActive ? (
        <Enroll
          setEnrollActive={setEnrollActive}
          Package_Id={EnrollActive}
          courseId={CourseId}
        />
      ) : (
        ""
      )}
      {/* Middle Section */}
      {!SidebarOpen ? (
        <section className={Styles.MiddleSection}>
          <div>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              PageName={"Materials"}
            />
          </div>

          <div className={Styles.OnlineClassTopSection}>
            <div>
              <h3 style={{ textAlign: "center", fontWeight: "500" }}>
                Please buy the package to unlock this section.
              </h3>
            </div>
          </div>

          <section style={{ background: "#f4f9ff", paddingBottom: "60px" }}>
            <div style={{ margin: "30px" }}>
              <div className={Styles.Browse_Package_Section}>
                <div>
                  {" "}
                  <h1 className={Styles.Heading}>
                    <span className="Font_Rajdhani">Browse Courses </span>
                  </h1>
                  {/* <p>
                    In this free interactive webinar, our IELTS expert will help
                    us be ready to answer
                    <br /> a range of common questions types for Part 1 of the
                    Speaking Test.
                  </p>{" "} */}
                </div>
                <div className={Styles.Browse_Package_Scroller}>
                  <div onClick={() => BrowsePackageRef?.current?.slickPrev()}>
                    <FaCircleArrowLeft />
                  </div>
                  <div onClick={() => BrowsePackageRef?.current?.slickNext()}>
                    {" "}
                    <FaCircleArrowRight />
                  </div>
                </div>
              </div>
            </div>

            <div style={{ margin: "0 30px" }}>
              <Slider
                ref={BrowsePackageRef}
                style={{ width: "100%" }}
                {...BrowsePacakgesettings}
              >
                {PackageRec?.map((el, i) => (
                  <div key={el._id}>
                    <PackageCard
                      setEnrollActive={setEnrollActive}
                      EnrollActive={EnrollActive}
                      setCourseId={setCourseId}
                      el={el}
                      Styles={Styles2}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </section>
          {/* <div className={Styles.OnlineClassTopSection}>
            <div>
              <h3>Upcoming Mock Test</h3>
              <p>
                IELTS is an English language test for study, migration or work.
                Over three million people take out test every year. IELTS is
                accepted by more than 11,000 employers, universities & schools
                around the world.
              </p>
            </div>
            <div>
              <select>
                <option>This Week</option>
              </select>
            </div>
          </div> */}

          {/* <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            className={Styles.DashboardBtn}
          >
            <button
              onClick={() => Navigate("/T-LAT")}
              className={Styles.custom_btn}
            >
              Start Test
            </button>
          </div>
          <div>
            <div className={Styles.UpcomingMock_Test_Card}>
              <div className={Styles.TopSection}>
                <div>
                  <p>Package Name</p>
                  <h4>
                    IELTS Academic and IELTS General <br />
                    Training Mock Test.
                  </h4>
                </div>
                <div>
                  <p>Other Links</p>
                  <div className={Styles.DashboardBtn}>
                    <button>Course Material</button>
                    <button>Cheat Sheet</button>
                  </div>
                </div>
              </div>

              <div className={Styles.IncludeTest}>
                <p>Included Mock Test:</p>
              </div>

              <div className={Styles.MockCardTest}>
                <div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                </div>
                <hr />
                <div></div>
                <div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>
                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*  2nd design section card mock test */}

          {/* <div>
            <div className={Styles.UpcomingMock_Test_Card}>
              <div className={Styles.TopSection}>
                <div>
                  <p>Package Name</p>
                  <h4>
                    IELTS Academic and IELTS General <br />
                    Training Mock Test.
                  </h4>
                </div>
                <div>
                  <p>Other Links</p>
                  <div className={Styles.DashboardBtn}>
                    <button>Course Material</button>
                    <button>Cheat Sheet</button>
                  </div>
                </div>
              </div>
              <div className={Styles.IncludeTest}>
                <p>Included Mock Test:</p>
              </div>
              <div className={Styles.MockCardTest}>
                <div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>
                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                </div>
                <hr />
                <div></div>
                <div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package2}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                  <div className={Styles.Internal_Mock_Card}>
                    <div>
                      <p className={Styles.package1}>Paper Pen</p>
                      <p>-</p>
                      <p className={Styles.MockTest01}>Writing Mock Test 01</p>
                    </div>

                    <div>
                      <p>
                        <hr />
                      </p>
                    </div>
                    <div className={Styles.ViewTest}>
                      <p>View Test</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      ) : (
        " "
      )}

      {Mobile_Screen <= 480 ? "" : <RightSidePanel />}
      {/* Right Section  */}
    </div>
  );
}
