import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function OrderStatus() {
  const { id } = useParams();
  const Navigate = useNavigate();
  const [Data, setData] = useState();

  const Get_Orders = async () => {
    try {
      const inputJson = {};
      const { data } = await axios.post(
        `https://api.testifyi.com/student/order/status/${id}`,
        inputJson
      );
      if (data?.Result == "success") {
        Navigate(`/order/${data?.id}?resp=success`);
      } else {
        Navigate(`/order/${data?.id}?resp=failed`);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    Get_Orders();
  }, []);

  return (
    <>
      <div>Loading...</div>
    </>
  );
}
