export const QUESTION_RECORD_LIST_REQ = "QUESTION_RECORD_LIST_REQ";
export const QUESTION_RECORD_LIST_SUCCESS = "QUESTION_RECORD_LIST_SUCCESS";
export const QUESTION_RECORD_LIST_FAILED = "QUESTION_RECORD_LIST_FAILED";
export const QUESTION_RECORD_LIST_RESET = "QUESTION_RECORD_LIST_RESET";

export const SUBMIT_QUESTION_RECORD_REQ = "SUBMIT_QUESTION_RECORD_REQ";
export const SUBMIT_QUESTION_RECORD_SUCCESS = "SUBMIT_QUESTION_RECORD_SUCCESS";
export const SUBMIT_QUESTION_RECORD_FAILED = "SUBMIT_QUESTION_RECORD_FAILED";
export const SUBMIT_QUESTION_RECORD_RESET = "SUBMIT_QUESTION_RECORD_RESET";

export const TLAT_TEST_RESULT_REQ = "TLAT_TEST_RESULT_REQ";
export const TLAT_TEST_RESULT_SUCCESS = "TLAT_TEST_RESULT_SUCCESS";
export const TLAT_TEST_RESULT_FAILED = "TLAT_TEST_RESULT_FAILED";
export const TLAT_TEST_RESULT_RESET = "TLAT_TEST_RESULT_RESETF";

export const TLAT_ANSWER_RESULT_REQ = "TLAT_ANSWER_RESULT_REQ";
export const TLAT_ANSWER_RESULT_SUCCESS = "TLAT_ANSWER_RESULT_SUCCESS";
export const TLAT_ANSWER_RESULT_FAILED = "TLAT_ANSWER_RESULT_FAILED";
export const TLAT_ANSWER_RESULT_RESET = "TLAT_ANSWER_RESULT_RESET";
