import React from "react";
import { IoMdArrowRoundBack, IoMdNotifications } from "react-icons/io";
import { GrMail } from "react-icons/gr";
import Styles from "./SidePanel.module.css";
import { TfiAlignJustify } from "react-icons/tfi";
const TopBar = ({ PageName, SetSideBarOpen, SidebarOpen }) => {
  const MobileScreen = window.innerWidth;

  return (
    <div>
     
      <div style={{marginTop:"-25px"}} className={Styles.TopBar}>
        {MobileScreen <= 780 ? (
          <h2
            onClick={() => SetSideBarOpen(!SidebarOpen)}
            style={{ cursor: "pointer" }}
          >
            <TfiAlignJustify />
          </h2>
        ) : (
          ""
        )}
        <div>
          <h2>{PageName}</h2>
        </div>
        <div>
          <p>
            <IoMdNotifications />{" "}
          </p>
          <p>
            <GrMail />{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
