import RightSidePanel from "../../Components/RightSidePanel";
import SidePanel from "../../Components/SidePanel";
import TopBar from "../../Components/TopBar";
import Styles from "./OnlineClass.module.css";
import { useState } from "react";
import { BsCalendar3, BsFillArrowRightCircleFill } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { useEffect } from "react";
export default function OnlineClass() {
  const [OnlineClassList, setOnlineClassList] = useState([1, 2, 3, 4]);
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  // const [RightBarOpen, setRightBarOpen] = useState(false);
  const Mobile_Screen = window.innerWidth;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={Styles.Dashboard}>
      {/* SidePanel */}

      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Online Classes"
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Online Classes"
        />
      ) : (
        ""
      )}
      {/* Middle Section */}
      {!SidebarOpen ? (
        <section className={Styles.MiddleSection}>
          <div>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              PageName={"Online Classes"}
            />
          </div>
          <div className={Styles.OnlineClassTopSection}>
            <div>
              <h3>Upcoming Online Classes</h3>
              <p>
                IELTS is an English language test for study, migration or work.
                Over three million people take out test every year. IELTS is
                accepted by more than 11,000 employers, universities & schools
                around the world.
              </p>
            </div>
            <div>
              <select>
                <option>This Week</option>
              </select>
            </div>
          </div>
          {OnlineClassList.map((el, i) => (
            <div key={i + 78} className={Styles.OnlineClassContainer}>
              <div className={Styles.OnlineClassCard}>
                <div>
                  <div>
                    <p>package name</p>
                  </div>
                  <h4>
                    Online Classes - IELTSMaterial
                    <br />
                    Master Program
                  </h4>
                  <p>
                    Lecturer: <span>Aman Kaur Kahlon </span>
                  </p>
                </div>
                <hr />
                <div className={Styles.ClassDate}>
                  <div>
                    <div>
                      <p>
                        <BsCalendar3 />
                      </p>
                      <p>Date</p>
                    </div>
                    <div>
                      <p>Monday, 20/03/2023</p>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p>
                        <BiTime />
                      </p>
                      <p>Time</p>
                    </div>
                    <div>
                      <p>2pm to 6pm</p>
                    </div>
                  </div>
                </div>
                <hr />
                <div className={Styles.BtmClass}>
                  <div>
                    <h4>Live Class Link</h4>
                  </div>
                  <div>
                    <button>Link</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      ) : (
        ""
      )}

      {Mobile_Screen >= 780 ? <RightSidePanel /> : ""}
      {/* Right Section  */}
    </div>
  );
}
