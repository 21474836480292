export const faqs = [
  {
    id: 1,
    header: "What is Testify?",
    text: `Testify is a platform that helps people prepare for English language tests like IELTS, PTE, and the Duolingo English Test. We also offer General English classes to improve your overall English skills.`,
  },
  {
    id: 2,
    header: "What services does Testify offer?",
    text: `Testify provides preparation courses for IELTS (Academic and General), PTE (Academic and Core), the Duolingo English Test, and General English classes for all levels.`,
  },
  {
    id: 3,
    header: "How can Testify help me improve my English?",
    text: `Testify gives you personalized lessons to help you reach your goals. Whether you're preparing for a test or just want to improve your English, our teachers and study plans will help you succeed.`,
  },
  {
    id: 4,
    header: "Why choose Testify for your English test preparation?",
    text: `At Testify, we offer expert guidance, flexible schedules, and useful resources to help you do well in your English exams. Our methods have helped many students get high scores.`,
  },
  {
    id: 5,
    header: "Can I take courses online with Testify?",
    text: `Yes! Testify offers online classes so you can prepare for your English test from anywhere, with the help of our expert teachers.`,
  },
  {
    id: 6,
    header:
      "What makes Testify different from other English training providers?",
    text: `Testify offers personalized lessons, experienced teachers, and flexible learning options. We make sure to give each student the support they need to achieve their goals.`,
  },
];
