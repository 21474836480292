import {
  BOOK_A_DEMO_FAILED,
  BOOK_A_DEMO_REQ,
  BOOK_A_DEMO_SUCCESS,
} from "../Constant/BookDemoConstant";
import api from "../Core/api";

export const Book_Demo = (BookContactDet) => async (dispatch) => {

  try {
    dispatch({ type: BOOK_A_DEMO_REQ });

    const { data } = await api.post("/contact/booking", BookContactDet);

    dispatch({ type: BOOK_A_DEMO_SUCCESS, payload: data?.Result });
  } catch (error) {
    dispatch({
      type: BOOK_A_DEMO_FAILED,
      payload:
        error.response && error.response.data.Result
          ? error.response.data.Result
          : error.message,
    });
  }
};
