import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

api.interceptors.request.use((config) => {
  const authToken = localStorage.getItem("_testifyiTkn");
  if (authToken) config.headers.Authorization = `Bearer ${authToken}`;
  return config;
});

// axiosRetry(api, {
//   retries: 10,
//   retryDelay: () => 4000,
//   retryCondition: (error) => {
//     if (error.response.status === 404) {
//       return true;
//     } else {
//       return false;
//     }
//   },
// });

export default api;
