import {
  COURSE_LIST_ALL_FAIL,
  COURSE_LIST_ALL_REQUEST,
  COURSE_LIST_ALL_SUCCESS,
} from "../Constant/CourseConstant";

export const courseListAllRecReducer = (
  state = {},
  { type, payload, total }
) => {
  switch (type) {
    case COURSE_LIST_ALL_REQUEST: {
      return { loading: true };
    }

    case COURSE_LIST_ALL_SUCCESS: {
      return { loading: false, CouresesRec: payload, total };
    }

    case COURSE_LIST_ALL_FAIL: {
      return { loading: false, error: payload };
    }
    default:
      return state;
  }
};
