import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import TopFooter from "../../Components/TopFooterSection";
import Footer from "../../Components/Footer";
import Styles from "./Fotgot.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Change_Password_Model,
  PasswordValidator,
} from "../../Model/UserModel";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CHANGE_PASSWORD, VERIFY_PASSWORD_URL } from "../../Action/UserAction";
import Loading from "../../Components/Loading";
import {
  USER_PASSWORD_CHANGE_FAILED,
  USER_PASSWORD_CHANGE_RESET,
  USER_VERIFY_URL_FAILED,
} from "../../Constant/UserConstant";
const SetPassword = () => {
  const [PasswordForm, setPasswordForm] = useState(Change_Password_Model);
  const [PasswordVisible, setPasswordVisible] = useState(false);
  const [PasswordVisible1, setPasswordVisible1] = useState(false);
  const [PasswordError, setPasswordError] = useState({
    Error: "",
    Success: "",
  });

  const { loading, success, error } = useSelector(
    (s) => s.VERIFY_PASSWORD_URL_REDUCER
  );
  const {
    loading: Cloading,
    success: Csuccess,
    error: Cerror,
  } = useSelector((s) => s.CHANGE_PASSWORD_REDUCER);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const key = query.get("k");
  const text = query.get("t");
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const handleChange = (e) => {
    if (e.target.name === "ConfirmPassword") {
      if (PasswordForm?.Password !== e.target.value) {
        setPasswordError({
          Error: "Error - New Password & Confirm Password not matched !",
        });
      } else {
        setPasswordError({
          Success: "Password Matched !",
        });
      }
    } else if (e.target.name === "Password") {
      if (PasswordForm?.ConfirmPassword !== e.target.value) {
        setPasswordError({
          Error: "Error - New Password & Confirm Password not matched !",
        });
      } else {
        setPasswordError({
          Success: "Password Matched !",
        });
      }
    }
    setPasswordForm({ ...PasswordForm, [e.target.name]: e.target.value });
  };

  const handleLogin = (e) => {
    e.preventDefault();

    const Password_Validator = PasswordValidator(PasswordForm?.Password);

    if (Password_Validator === "success") {
      if (PasswordForm?.Password === PasswordForm?.ConfirmPassword) {
        dispatch(
          CHANGE_PASSWORD({
            text,
            k: key,
            password: PasswordForm?.Password,
          })
        );
      }
    } else {
      dispatch({
        type: USER_PASSWORD_CHANGE_FAILED,
        payload: Password_Validator,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (text && key) {
      dispatch(VERIFY_PASSWORD_URL({ text, k: key }));
    } else {
      dispatch({
        type: USER_VERIFY_URL_FAILED,
        payload: "Error - Invalid url !",
      });
    }
  }, [dispatch, text, key]);

  useEffect(() => {
    if (Csuccess) {
      setPasswordError(Change_Password_Model);
      setTimeout(() => {
        dispatch({ type: USER_PASSWORD_CHANGE_RESET });
        Navigate("/login");
      }, 3000);
    }
  }, [Csuccess]);



  return (
    <>
      {/* navbar Section */}
      <Navbar Page={"Login"} />
      {loading || Cloading ? <Loading /> : ""}
      {/* Fixed Menu Section */}
      <section className={Styles.Login}>
        <div className={Styles.container}>
          <div className={Styles.wrapper}>
            {error ? (
              ""
            ) : (
              <div className={Styles.title}>
                <h1 className="Font_Rajdhani">Change Password </h1>
                <p className="Font_Rajdhani_normal">Access to our dashboard</p>
              </div>
            )}
            {error ? (
              <h4
                style={{ color: "red", textAlign: "center", padding: "30px" }}
              >
                Error - Invalid Url !
              </h4>
            ) : (
              <form onSubmit={handleLogin}>
                <div className={Styles.row}>
                  <i className="fas fa-lock"></i>
                  <input
                    name="Password"
                    value={PasswordForm.Password}
                    onChange={handleChange}
                    type={PasswordVisible1 ? "text" : "password"}
                    placeholder="New Password"
                    required
                  />
                  {PasswordVisible1 ? (
                    <span
                      className={Styles.VisiblePass}
                      onClick={() => setPasswordVisible1(!PasswordVisible1)}
                    >
                      <AiOutlineEye />
                    </span>
                  ) : (
                    <span
                      className={Styles.VisiblePass}
                      onClick={() => setPasswordVisible1(!PasswordVisible1)}
                    >
                      <AiOutlineEyeInvisible />
                    </span>
                  )}
                </div>
                <div className={Styles.row}>
                  <i className="fas fa-lock"></i>
                  <input
                    name="ConfirmPassword"
                    value={PasswordForm.ConfirmPassword}
                    onChange={handleChange}
                    type={PasswordVisible ? "text" : "password"}
                    placeholder="Confirm Password"
                    required
                  />
                  {PasswordVisible ? (
                    <span
                      className={Styles.VisiblePass}
                      onClick={() => setPasswordVisible(!PasswordVisible)}
                    >
                      <AiOutlineEye />
                    </span>
                  ) : (
                    <span
                      className={Styles.VisiblePass}
                      onClick={() => setPasswordVisible(!PasswordVisible)}
                    >
                      <AiOutlineEyeInvisible />
                    </span>
                  )}
                </div>

                <div className={(Styles.row, Styles.button)}>
                  <input
                    type="submit"
                    disabled={
                      !PasswordForm?.Password ||
                      PasswordForm?.Password != PasswordForm?.ConfirmPassword
                    }
                    value={Cloading ? "Loading..." : "Submit"}
                  />
                </div>
                {Cerror ? (
                  <p style={{ color: "red", textAlign: "center" }}>{Cerror}</p>
                ) : (
                  ""
                )}

                {Csuccess || PasswordError?.Success ? (
                  <p style={{ color: "green", textAlign: "center" }}>
                    {Csuccess || PasswordError?.Success}
                  </p>
                ) : (
                  ""
                )}

                {PasswordError?.Error ? (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {PasswordError?.Error}
                  </p>
                ) : (
                  ""
                )}
                <div className={Styles.signup_link}></div>
              </form>
            )}
          </div>
        </div>
      </section>
      {/* Top Footer Section*/}
      <TopFooter />
      {/* Footer Section */}
      <Footer />
    </>
  );
};

export default SetPassword;
