import { RxDashboard } from "react-icons/rx";
import Styles from "./SidePanel.module.css";
import { MdModeEdit } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { LOGOUT } from "../Action/UserAction";
import { TfiAlignJustify } from "react-icons/tfi";
import { IoMdArrowRoundBack } from "react-icons/io";
export default function SidePanel({ Page, SetSideBarOpen, SidebarOpen }) {
  const { Login_User_Loading, Login_User, Login_User_Error } = useSelector(
    (state) => state.USER_LOGIN_REDUCER
  );
  const MobileScreen = window.innerWidth;

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(LOGOUT());
  };

  useEffect(() => {
    if (!Login_User) {
      Navigate("/");
    }
  });

  return (
    <>
      <div className={Styles.SidePanel}>
        <div className={Styles.TopSection}>
          <div className={Styles.UserCircle}>
            <img src="/assets/image/user.svg" alt="user" />
            {MobileScreen <= 780 ? (
              <h2
                onClick={() => SetSideBarOpen(!SidebarOpen)}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "0px",
                  right: "20px",
                }}
              >
                <TfiAlignJustify />
              </h2>
            ) : (
              ""
            )}
            <div>
              <span>
                <MdModeEdit />
              </span>
            </div>
          </div>
          <div>
            <h4>
              {Login_User?.First_Name
                ? Login_User?.First_Name + " " + Login_User?.Last_Name
                : ""}
            </h4>
            {/* <p>Joined at Dec 2023</p> */}
            {/* <button>Package</button> */}
            {/* <p>Speaking & Writing</p> */}
            {/* <p>Intermediate Package 002</p> */}
            <div className={Styles.Logout}>
              <button onClick={handleLogout}>
                <span>
                  <BiLogOut />
                </span>
                <span> Logout</span>
              </button>
            </div>
          </div>
        </div>
        <span
          onClick={() => Navigate("/")}
          style={{
            fontSize: "25px",
            marginBottom: "5px",
            position: "absolute",
            left: "10px",
            top: "5px",
            color: "white",
            cursor: "pointer",
          }}
        >
          <IoMdArrowRoundBack />
        </span>

        <div className={Styles.BottomSection}>
          <Link onClick={() => SetSideBarOpen(false)} to="/dashboard">
            <div
              className={
                Page === "Dashboard" ? Styles.BtmActive : Styles.BtmDeActive
              }
            >
              <p>
                <RxDashboard />{" "}
              </p>
              <p>Dashboard</p>
            </div>
          </Link>
          <Link onClick={() => SetSideBarOpen(false)} to="/Attendance">
            <div
              className={
                Page === "Attendance" ? Styles.BtmActive : Styles.BtmDeActive
              }
            >
              <p>
                <RxDashboard />
              </p>
              <p>Attendance</p>
            </div>
          </Link>

          <Link onClick={() => SetSideBarOpen(false)} to="/Purchases">
            <div
              className={
                Page === "Purchases" ? Styles.BtmActive : Styles.BtmDeActive
              }
            >
              <p>
                <RxDashboard />{" "}
              </p>
              <p>Purchases</p>
            </div>
          </Link>
          {/* <Link onClick={() => SetSideBarOpen(false)} to="/online-classes">
            <div
              className={
                Page === "Online Classes"
                  ? Styles.BtmActive
                  : Styles.BtmDeActive
              }
            >
              <p>
                <RxDashboard />{" "}
              </p>
              <p>Online Classes</p>
            </div>
          </Link> */}
          <Link onClick={() => SetSideBarOpen(false)} to="/course-material">
            <div
              className={
                Page === "Materials" ? Styles.BtmActive : Styles.BtmDeActive
              }
            >
              <p>
                <RxDashboard />{" "}
              </p>
              <p>Materials</p>
            </div>
          </Link>
          <Link onClick={() => SetSideBarOpen(false)} to="/Academy">
            <div
              className={
                Page === "Practice Test" ? Styles.BtmActive : Styles.BtmDeActive
              }
            >
              <p>
                <RxDashboard />{" "}
              </p>
              <p>Academy</p>
            </div>
          </Link>
          <Link onClick={() => SetSideBarOpen(false)} to="/mock-test-dashboard">
            <div
              className={
                Page === "Mock Test" ? Styles.BtmActive : Styles.BtmDeActive
              }
            >
              <p>
                <RxDashboard />{" "}
              </p>
              <p>Mock Test</p>
            </div>
          </Link>

          <Link onClick={() => SetSideBarOpen(false)} to="/live-classes">
            <div
              className={
                Page === "Live Classes" ? Styles.BtmActive : Styles.BtmDeActive
              }
            >
              <p>
                <RxDashboard />{" "}
              </p>
              <p>Live Classes</p>
            </div>
          </Link>

          {/* <Link onClick={() => SetSideBarOpen(false)} to="/mock-test-dashboard">
            <div
              className={
                Page === "Mock Test" ? Styles.BtmActive : Styles.BtmDeActive
              }
            >
              <p>
                <RxDashboard />
              </p>
              <p>Mock Test</p>
            </div>
          </Link>
          <Link onClick={() => SetSideBarOpen(false)}>
            <div
              className={
                Page === "Videos" ? Styles.BtmActive : Styles.BtmDeActive
              }
            >
              <p>
                <RxDashboard />
              </p>
              <p>Videos</p>
            </div>
          </Link> */}
        </div>
      </div>
    </>
  );
}
