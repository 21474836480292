import { useCallback, useState } from "react";
import Footer from "../../Components/Footer";
import TopFooter from "../../Components/TopFooterSection";
import Styles from "./Package.module.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar2 from "../../Components/Navbar2";
import { useDispatch, useSelector } from "react-redux";
import { Package_List } from "../../Action/PacakgeAction";
import PackageCard from "../../Components/PackageCard";
import Pagination from "../../Components/Pagination";
import Loading from "../../Components/Loading";
import Enroll from "../Home/Enroll";

export default function Package() {
  const [EnrollActive, setEnrollActive] = useState(false);
  const [CourseId, setCourseId] = useState("");
  const [PackageFilter, setPackageFilter] = useState({
    page_no: 1,
    page_size: 10,
  });
  const {
    loading: loadingPack,
    PackageRec,
    total,
  } = useSelector((s) => s.Package_List_Reducer);

  const [QuestionSelected, setQuestionSelected] = useState({
    IELTS_TEST: "",
    Step_1: "",
    Step_2: "",
    Step_3: "",
  });

  const CourseModel = [
    { Q: "IELTS", Option: "A", id: "670cdc063ac09dd1afe0b5af" },
    { Q: "PTE", Option: "B", id: "670cdc0a3ac09dd1afe0b5b4" },
    { Q: "Duolingo", Option: "C", id: "670cdc133ac09dd1afe0b5ba" },
    { Q: "General English", Option: "D", id: "670cdc1e87bfc3160986a2ab" },
  ];
  const TestType_Model = [
    { Q: "Paper Based Test", Option: "A", id: 1 },
    { Q: "Computer Based Test", Option: "B", id: 2 },
  ];

  const Step2_Ielts = [
    {
      Q: "Academic",
      Option: "A",
      id: "Academic",
    },
    { Q: "General", Option: "B", id: "General" },
  ];

  const Step2_PTE = [
    {
      Q: "PTE Academic",
      Option: "A",
      id: "PTE Academic",
    },
    { Q: "PTE CORE", Option: "B", id: "PTE CORE" },
  ];
  const [Step, setStep] = useState(1);

  const handleStep1 = (Q, id) => {
    setTimeout(() => {
      if (Q == "IELTS" || Q == "PTE") {
        setStep(2);
      } else {
        Navigate(`/course?courseid=${id}`);
      }
    }, 500);

    setQuestionSelected({ ...QuestionSelected, IELTS_TEST: Q, Step_1: id });
  };

  const handleStep2 = (Q, id) => {
    setTimeout(() => {
      setStep(3);
    }, 500);
    setQuestionSelected({ ...QuestionSelected, Step_2: id });
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  let courseid = query.get("courseid");
  let SubCategory = query.get("SubCategory");
  let testType = query.get("testType");

  const get_Packages = useCallback(() => {
    dispatch(
      Package_List({ ...PackageFilter, courseid, SubCategory, testType })
    );
  }, [dispatch, PackageFilter, courseid, SubCategory, testType]);

  useEffect(() => {
    get_Packages();
  }, [get_Packages]);

  const ScrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    ScrollTop();
  }, [PackageFilter]);
  const Navigate = useNavigate();
  return (
    <div>
      {/*Navbar */}
      <Navbar2 Page={"Course"} />
      {/*Banner Section */}
      {loadingPack ? <Loading /> : ""}
      <div className={Styles.Package}>
        {EnrollActive ? (
          <Enroll
            setEnrollActive={setEnrollActive}
            Package_Id={EnrollActive}
            courseId={CourseId}
          />
        ) : (
          ""
        )}
        <section className={Styles.showcase}>
          <img src={"/assets/image/Pacakes_Banner.jpg"} alt="Banner" />
          <div className={Styles.overlay}>
            <div>
              <div>
                <p>
                  <span> HOME</span> /<span> ALL COURSES </span>{" "}
                </p>
              </div>
              <div>
                <h1 className="Font_Rajdhani">All Courses</h1>
              </div>
              <div>
                {/* <p>
                  We help internationals to get enrolled into the best possible
                  career-oriented courses <br /> along with the selection of
                  colleges and universities across Australia.
                </p> */}
              </div>
            </div>
          </div>
        </section>
        {/*Section 2*/}
        <section>
          <div className={Styles.Custom_Margin}>
            <div>
              <div>
                <div className={Styles.FilterCard}>
                  <div>
                    <h4>Filter</h4>
                    <button
                      onClick={() => {
                        Navigate("/Course");
                        setQuestionSelected({
                          IELTS_TEST: "",
                          Step_1: "",
                          Step_2: "",
                          Step_3: "",
                        });
                      }}
                    >
                      Clear Filters
                    </button>
                  </div>
                  <hr />
                  <div>
                    <h4>Course</h4>
                    {CourseModel?.map((ev) => (
                      <div
                        onClick={() => {
                          handleStep1(ev?.Q, ev?.id);
                          Navigate(`/Course?courseid=${ev?.id}`);
                        }}
                      >
                        <input
                          type="radio"
                          name="course"
                          checked={ev?.Q === QuestionSelected?.IELTS_TEST}
                        />{" "}
                        <p>{ev?.Q}</p>
                      </div>
                    ))}
                    {/* <div>
                      <input type="checkbox" /> <p>Beginner</p>
                    </div>
                    <div>
                      <input type="checkbox" />
                      <p>Intermediate</p>
                    </div>
                    <div>
                      <input type="checkbox" />
                      <p>Advance</p>
                    </div> */}
                  </div>
                  <hr />

                  {QuestionSelected?.IELTS_TEST === "IELTS" ||
                  QuestionSelected?.IELTS_TEST === "PTE" ? (
                    <>
                      <div>
                        <h4>Sub Category</h4>

                        {QuestionSelected?.IELTS_TEST === "IELTS"
                          ? Step2_Ielts?.map((ev) => (
                              <div
                                onClick={() => {
                                  handleStep2(ev?.Q, ev?.id);
                                  Navigate(
                                    `/course?courseid=${QuestionSelected?.Step_1}&SubCategory=${ev?.id}`
                                  );
                                }}
                              >
                                <input
                                  type="radio"
                                  name="SubCategory"
                                  checked={ev?.Q === QuestionSelected?.Step_2}
                                />
                                <p>{ev?.Q}</p>
                              </div>
                            ))
                          : QuestionSelected?.IELTS_TEST === "PTE"
                          ? Step2_PTE?.map((ev) => (
                              <div
                                onClick={() => {
                                  setTimeout(() => {
                                    Navigate(
                                      `/course?courseid=${QuestionSelected?.Step_1}&subCategory=${ev?.id}`
                                    );
                                  }, 500);

                                  setQuestionSelected({
                                    ...QuestionSelected,
                                    Step_2: ev?.Q,
                                  });
                                }}
                              >
                                <input
                                  type="radio"
                                  name="SubCategory"
                                  checked={ev?.Q === QuestionSelected?.Step_2}
                                />{" "}
                                <p>{ev?.Q}</p>
                              </div>
                            ))
                          : ""}

                        {/* <div>
                          <input type="checkbox" /> <p>Reading</p>
                        </div>
                        <div>
                          <input type="checkbox" /> <p>Speaking</p>
                        </div> */}
                      </div>
                      <hr />
                    </>
                  ) : (
                    ""
                  )}

                  {QuestionSelected?.IELTS_TEST === "IELTS" ? (
                    <div>
                      <h4>Test Type</h4>
                      {TestType_Model?.map((ev) => (
                        <div
                          onClick={() => {
                            setTimeout(() => {
                              Navigate(
                                `/course?courseid=${QuestionSelected?.Step_1}&SubCategory=${QuestionSelected?.Step_2}&testType=${ev?.id}`
                              );
                            }, 500);

                            setQuestionSelected({
                              ...QuestionSelected,
                              Step_3: ev?.Q,
                            });
                          }}
                        >
                          <input
                            type="radio"
                            checked={ev?.Q === QuestionSelected?.Step_3}
                          />{" "}
                          <p>{ev?.Q}</p>
                        </div>
                      ))}
                      {/* <div>
                      <input type="checkbox" /> <p>Writing</p>
                    </div>
                    <div>
                      <input type="checkbox" /> <p>Reading</p>
                    </div>
                    <div>
                      <input type="checkbox" /> <p>Speaking</p>
                    </div> */}
                    </div>
                  ) : (
                    ""
                  )}
                  <div style={{ display: "none" }}>
                    <h4>Module</h4>
                    <div>
                      <p>Test Papers</p>
                      <p>
                        <BsFillArrowRightCircleFill />
                      </p>
                    </div>
                    <div>
                      <p>Mock Test</p>
                      <p>
                        <BsFillArrowRightCircleFill />
                      </p>
                    </div>
                    <div>
                      <p>One To One Counselling</p>
                      <p>
                        <BsFillArrowRightCircleFill />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => (window.location.href = "/contact-us")}
                  className={Styles.Expert}
                >
                  <img src="/assets/image/Expert.png" alt="expert_contact_us" />
                </div>
              </div>
              <div className={Styles.PackageCard_Container}>
                <div>
                  {PackageRec?.map((el, i) => (
                    <div key={el._id}>
                      <PackageCard
                        el={el}
                        Styles={Styles}
                        setCourseId={setCourseId}
                        setEnrollActive={setEnrollActive}
                      />
                    </div>
                    // <div
                    //   key={el._id}
                    //   onClick={() => Navigate("/course/details")}
                    //   className={Styles.card_design}
                    // >
                    //   <div
                    //     style={{
                    //       backgroundImage: `url(${el?.PackageId?.Image})`,
                    //     }}
                    //     className={`${Styles.Card1} ${Styles.Card1_Img}`}
                    //   >
                    //     <div>
                    //       <button>Speaking</button>
                    //       <button>Writing</button>{" "}
                    //     </div>
                    //   </div>
                    //   <div>
                    //     <div>
                    //       <p>
                    //         Duration:
                    //         <span style={{ color: "#0b3eff" }}>
                    //           {" "}
                    //           {el?.PackageId?.Duration} Weeks
                    //         </span>{" "}
                    //       </p>
                    //       <p style={{ fontSize: "19px", fontWeight: "600" }}>
                    //         {el?.PackageId?.Name}
                    //         {/* Speaking & Writing <br /> Intermediate Package 003 */}
                    //       </p>
                    //     </div>
                    //     <hr />
                    //     <div>
                    //       <p>
                    //         <strong>This package Includes : </strong>
                    //       </p>
                    //       <div className={Styles.SpeakingWriting}>
                    //         {el?.LIVE_CLASSES ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_LIVE_CLASSES.svg"
                    //                 alt="icon_LIVE_CLASSES"
                    //               />
                    //             </span>
                    //             <span>{el?.LIVE_CLASSES} LIVE CLASSES </span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.One_On_One_DOUBT_SESSIONS === "Yes" ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                    //                 alt="icon_LIVE_CLASSES"
                    //               />
                    //             </span>
                    //             <span>1:1 DOUBT SESSIONS </span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.DOUBT_SESSIONS === "Yes" ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_DOUBT_SESSIONS.svg"
                    //                 alt="icon_LIVE_CLASSES"
                    //               />
                    //             </span>
                    //             <span> DOUBT SESSIONS</span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}
                    //         {el?.SESSION_TESTS === "Yes" ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_SESSION_TESTS.svg"
                    //                 alt="icon_SESSION_TESTS"
                    //               />
                    //             </span>
                    //             <span>SESSION TESTS </span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}
                    //         {el?.STUDY_MATERIAL_ACCESS === "Yes" ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_STUDY_MATERIAL_ACCESS.svg"
                    //                 alt="icon_STUDY_MATERIAL_ACCESS"
                    //               />
                    //             </span>
                    //             <span> STUDY MATERIAL ACCESS</span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.COMPLETE_MOCK_TEST ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_MOCK_TEST.svg"
                    //                 alt="icon_MOCK_TEST"
                    //               />
                    //             </span>
                    //             <span>
                    //               {el?.COMPLETE_MOCK_TEST} COMPLETE MOCK TEST
                    //             </span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.COMPLETE_MOCK_TEST_AI ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_MOCK_TEST_AI.svg"
                    //                 alt="icon_MOCK_TEST"
                    //               />
                    //             </span>
                    //             <span>
                    //               {el?.COMPLETE_MOCK_TEST_AI} COMPLETE MOCK TEST
                    //               (POWERED BY AI)
                    //             </span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}
                    //         {el?.TESTIFYI_REALITY_TEST ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_reality_test.svg"
                    //                 alt="icon_reality_test"
                    //               />
                    //             </span>
                    //             <span>
                    //               {" "}
                    //               {el?.TESTIFYI_REALITY_TEST} TESTIFYI REALITY
                    //               TEST
                    //             </span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.MASTER_CLASS ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_Master_class.svg"
                    //                 alt="icon_Master_class"
                    //               />
                    //             </span>
                    //             <span>{el?.MASTER_CLASS} MASTER CLASS</span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.One_On_One_SPEAKING_SESSIONS === "Yes" ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_Speaking_Session.svg"
                    //                 alt="icon_Speaking_Session"
                    //               />
                    //             </span>
                    //             <span>1:1 SPEAKING SESSIONS</span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.LANGUAGE_PRACTICE_TEST ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_LANGUAGE_PRACTICE_TEST.svg"
                    //                 alt="icon_LANGUAGE_PRACTICE_TEST"
                    //               />
                    //             </span>
                    //             <span>
                    //               {el?.LANGUAGE_PRACTICE_TEST} TESTIFYI PRACTICE
                    //               TEST
                    //             </span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.GRAMMAR_TEST === "Yes" ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_GRAMMAR_TEST.svg"
                    //                 alt="icon_GRAMMAR_TEST"
                    //               />
                    //             </span>
                    //             <span>GRAMMAR TEST</span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.MOCK_INTERVIEW === "Yes" ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_Mock_INTERVIEW.svg"
                    //                 alt="icon_Mock_INTERVIEW"
                    //               />
                    //             </span>
                    //             <span>MOCK INTERVIEW</span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.GROUP_DISCUSSIONS === "Yes" ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_GROUP_DISCUSSIONS.svg"
                    //                 alt="icon_GROUP_DISCUSSIONS"
                    //               />
                    //             </span>
                    //             <span>GROUP DISCUSSIONS</span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.BUSINESS_ENGLISH_SESSIONS === "Yes" ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_BUSINESS_ENGLISH_SESSIONS.svg"
                    //                 alt="icon_BUSINESS_ENGLISH_SESSIONS"
                    //               />
                    //             </span>
                    //             <span>BUSINESS ENGLISH SESSIONS</span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}

                    //         {el?.SOFT_SKILLS_SESSION === "Yes" ? (
                    //           <p>
                    //             <span>
                    //               <img
                    //                 src="/assets/image/ProductCard-Icon/icon_SOFT_SKILLS_SESSION.svg"
                    //                 alt="icon_SOFT_SKILLS_SESSION"
                    //               />
                    //             </span>
                    //             <span>SOFT SKILLS SESSION</span>
                    //           </p>
                    //         ) : (
                    //           ""
                    //         )}
                    //       </div>
                    //     </div>
                    //     <hr />
                    //     <div className={Styles.Card_Menu}>
                    //       {el?.PackageId?.DiscountedPrice ? (
                    //         <div>
                    //           <p>₹ {el?.PackageId?.DiscountedPrice} /-</p>
                    //           <p className={Styles.LineThrough}>
                    //             ₹ {el?.PackageId?.ActualCost} /-{" "}
                    //           </p>
                    //         </div>
                    //       ) : (
                    //         <div>
                    //           <p>₹ {el?.PackageId?.ActualCost} /-</p>
                    //           <p> </p>
                    //         </div>
                    //       )}
                    //       <div>
                    //         <button> Buy Now</button>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                  ))}
                </div>
                <div>
                  <Pagination
                    Styles={Styles}
                    Filter={PackageFilter}
                    setFilter={setPackageFilter}
                    total={total ? total : 0}
                  />
                  {/* <div className={Styles.FAQ}>
                    <h1 className="Font_Rajdhani">
                      Frequently Asked Questions
                    </h1>
                    <div className={Styles.faq}>
                      <input id="faq-a" type="checkbox" />
                      <label for="faq-a">
                        <p className={Styles.faq_heading}>
                          <span>1</span>
                          {"  "} Can a Person extend their 482 visa ?
                        </p>
                        <div className={Styles.faq_arrow}></div>
                        <p className={Styles.faq_text}>
                          It is completely safe and totally legal! There will is
                          no record of this process to your shared Dropbox
                          users.
                        </p>
                      </label>
                      <input id="faq-b" type="checkbox" />
                      <label for="faq-b">
                        <p className={Styles.faq_heading}>
                          <span>2</span> Is a person holding a 482 visa eligible
                          to work in Australia ?
                        </p>
                        <div className={Styles.faq_arrow}></div>
                        <p className={Styles.faq_text}>
                          Does a Person need to have an employer to sponsor them
                          so as to apply for a TSS visa ?
                          <strong>
                            In order to control the risk and secure the space
                            you earned, we will gradually process it.
                          </strong>{" "}
                          during this time you can still use your account as
                          normal as usual.
                        </p>
                      </label>
                      <input id="faq-c" type="checkbox" />
                      <label for="faq-c">
                        <p className={Styles.faq_heading}>
                          <span>3</span> How does one apply for a TSS visa ?
                        </p>
                        <div className={Styles.faq_arrow}></div>
                        <p className={Styles.faq_text}>
                          NO ACCESS TO YOUR PERSONAL ACCOUNT OR INFO IS
                          REQUIRED! All I need from you is your Dropbox referral
                          link.
                        </p>
                      </label>
                    </div>{" "}
                    <div>
                      <hr />{" "}
                    </div>
                  </div> */}

                  {/* <div className={Styles.BrowseService}>
                    <h1 className="Font_Rajdhani">Browse Other Services</h1>
                    <div>
                      <div>
                        <div>
                          <img
                            src="/assets/image/TestPapers.png"
                            alt="testPapers"
                          />
                        </div>
                        <div>Live Classes</div>
                        <div className={Styles.ArrowHover}>
                          <BsFillArrowRightCircleFill />{" "}
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            src="/assets/image/TestPapers.png"
                            alt="testPapers"
                          />
                        </div>
                        <div>Upcoming Mock Test</div>
                        <div className={Styles.ArrowHover}>
                          <BsFillArrowRightCircleFill />{" "}
                        </div>
                      </div>
                      <div>
                        <div>
                          <img
                            src="/assets/image/TestPapers.png"
                            alt="testPapers"
                          />
                        </div>
                        <div>All Test papers</div>
                        <div className={Styles.ArrowHover}>
                          <BsFillArrowRightCircleFill />{" "}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Top Footer Section*/}
      <TopFooter />
      {/* Footer Section */}
      <Footer />
    </div>
  );
}
