import React, { useState } from "react";
import SidePanel from "../../Components/SidePanel";
import Styles from "./CourseMaterial.module.css";
import TopBar from "../../Components/TopBar";
import { IoCheckmarkDone, IoNewspaperOutline } from "react-icons/io5";
import Slider from "react-slick";
import { IoArrowForwardCircle, IoArrowBackCircleSharp } from "react-icons/io5";
import { PiVideo } from "react-icons/pi";
import { LuFileSpreadsheet } from "react-icons/lu";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { GrCopy } from "react-icons/gr";
import { useRef } from "react";
const AccessCourseMaterial = () => {
  const [ActiveCard, setActiveCard] = useState(2);
  const RightScroll = useRef();
  const [SidebarOpen, SetSideBarOpen] = useState(false);
  const [RightBarOpen, setRightBarOpen] = useState(false);
  const Mobile_Screen = window.innerWidth;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Mobile_Screen <= 480 ? 1 : 4,
    slidesToScroll: 1,
  };

  return (
    <div className={Styles.Dashboard}>
      {/* SidePanel */}

      {Mobile_Screen >= 780 ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Course Material"
        />
      ) : (
        ""
      )}

      {Mobile_Screen <= 480 && SidebarOpen ? (
        <SidePanel
          SetSideBarOpen={SetSideBarOpen}
          SidebarOpen={SidebarOpen}
          Page="Course Material"
        />
      ) : (
        ""
      )}
      {!SidebarOpen ? (
        <section className={Styles.MiddleSection2}>
          <div>
            <TopBar
              SetSideBarOpen={SetSideBarOpen}
              SidebarOpen={SidebarOpen}
              PageName={"Access Your Course Material"}
            />
          </div>

          <div>
            <div className={Styles.ViewCourseTop}>
              <div>
                <h4>View Course Material For</h4>
              </div>
              <div>
                <span>Sort By :</span>
                <select>
                  <option>Last 3 Days </option>
                </select>
              </div>
            </div>

            <div className={Styles.ViewCourse}>
              <Slider ref={RightScroll} {...settings}>
                <div>
                  <div
                    onClick={() => setActiveCard(1)}
                    className={
                      ActiveCard === 1
                        ? Styles.SpeakingWritingActive
                        : Styles.SpeakingWriting
                    }>
                    <p>Purchased On: 17 Jan 2023</p>
                    <h5>
                      Speaking & Writing <br />
                      Intermediate package 001
                    </h5>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setActiveCard(2)}
                    className={
                      ActiveCard === 2
                        ? Styles.SpeakingWritingActive
                        : Styles.SpeakingWriting
                    }>
                    <p>Purchased On: 17 Jan 2023</p>
                    <h5>
                      Speaking & Writing <br />
                      Intermediate package 002
                    </h5>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setActiveCard(3)}
                    className={
                      ActiveCard === 3
                        ? Styles.SpeakingWritingActive
                        : Styles.SpeakingWriting
                    }>
                    <p>Purchased On: 17 Jan 2023</p>
                    <h5>
                      Speaking & Writing <br />
                      Intermediate package 003
                    </h5>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setActiveCard(4)}
                    className={
                      ActiveCard === 4
                        ? Styles.SpeakingWritingActive
                        : Styles.SpeakingWriting
                    }>
                    <p>Purchased On: 17 Jan 2023</p>
                    <h5>
                      Speaking & Writing <br />
                      Intermediate package 004
                    </h5>
                  </div>{" "}
                </div>

                <div>
                  <div
                    onClick={() => setActiveCard(5)}
                    className={
                      ActiveCard === 5
                        ? Styles.SpeakingWritingActive
                        : Styles.SpeakingWriting
                    }>
                    <p>Purchased On: 17 Jan 2023</p>
                    <h5>
                      Speaking & Writing <br />
                      Intermediate package 005
                    </h5>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setActiveCard(6)}
                    className={
                      ActiveCard === 6
                        ? Styles.SpeakingWritingActive
                        : Styles.SpeakingWriting
                    }>
                    <p>Purchased On: 17 Jan 2023</p>
                    <h5>
                      Speaking & Writing <br />
                      Intermediate package 006
                    </h5>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setActiveCard(7)}
                    className={
                      ActiveCard === 7
                        ? Styles.SpeakingWritingActive
                        : Styles.SpeakingWriting
                    }>
                    <p>Purchased On: 17 Jan 2023</p>
                    <h5>
                      Speaking & Writing <br />
                      Intermediate package 007
                    </h5>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setActiveCard(8)}
                    className={
                      ActiveCard === 8
                        ? Styles.SpeakingWritingActive
                        : Styles.SpeakingWriting
                    }>
                    <p>Purchased On: 17 Jan 2023</p>
                    <h5>
                      Speaking & Writing <br />
                      Intermediate package 008
                    </h5>
                  </div>{" "}
                </div>
              </Slider>
              <div className={Styles.RightArrow}>
                <span
                  onClick={() => RightScroll?.current.slickNext()}
                  style={{ cursor: "pointer" }}>
                  <IoArrowForwardCircle />
                </span>
              </div>

              {/* <div className={Styles.LeftArrow}>
              <span
                onClick={() => RightScroll?.current.slickPrev()}
                style={{ cursor: "pointer" }}>
                <IoArrowBackCircleSharp />
              </span>
                </div>*/}
            </div>
          </div>
          <div className={Styles.ViewCardDesign}>
            <div className={Styles.TopCardView}>
              <div>
                <span className={Styles.Purchased}>
                  Purchased On: 17 Jan 2023
                </span>
                <h5>
                  Speaking & Writing <br />
                  Intermediate package 00{ActiveCard}
                </h5>
              </div>
              <div>
                <div
                  stye={
                    Mobile_Screen <= 480
                      ? {
                          display: "flex",
                          gap: "30px",
                          flexDirection: "column",
                        }
                      : { display: "flex", gap: "30px" }
                  }>
                  <p className={Styles.progress}> </p>
                  <p>76% Completed</p>
                </div>
                <p>
                  <strong>Package Description:</strong>Whether you take IELTS on
                  computer or paper, you are taking some trusted English test.
                  Take computer-delivered IELTS
                  <br /> and get your result quicker. 450 Test Center Globally.
                </p>

                <div
                  style={
                    Mobile_Screen <= 480
                      ? { display: "flex", flexDirection: "column" }
                      : { display: "flex" }
                  }>
                  <p> Join Live Class:</p>
                  <a
                    href="http://meet.google.com/?authuser=0"
                    target="_blank"
                    rel="noreferrer">
                    http://meet.google.com/?authuser=0
                  </a>

                  <p
                    style={
                      Mobile_Screen <= 480
                        ? {
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            cursor: "pointer",
                          }
                        : { display: "flex", gap: "8px", cursor: "pointer" }
                    }>
                    <span style={{ color: "#f36b31" }}>
                      <GrCopy />
                    </span>
                    <span>COPY LINK</span>
                  </p>

                  <p>
                    <hr />
                  </p>

                  <p>
                    <strong>Next Class:</strong>
                    <span>3rd Jul 2023</span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <p
                style={{
                  color: "#f36b31",
                  fontSize: "13px",
                  fontWeight: "700",
                }}>
                What Included Course Material:
              </p>
              <table className={Styles.CourseMaterialTable}>
                {Mobile_Screen >= 780 ? (
                  <tbody>
                    <tr>
                      <td>
                        <div className={Styles.TableTopSection}>
                          <p> TestPapers </p>
                          <p>
                            <PiVideo />
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className={Styles.TableTopSection}>
                          <p>Lessons </p>
                          <p>
                            <HiOutlineClipboardCopy />
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className={Styles.TableTopSection}>
                          <p>Cheat Sheets </p>
                          <p>
                            <IoNewspaperOutline />
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className={Styles.TableTopSection}>
                          <p> Mock Test </p>
                          <p>
                            <LuFileSpreadsheet />
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className={Styles.TableRow}>
                          <div>
                            <p>Writing Test Paper 1</p>
                            <p>Writing Test Paper 2</p>
                            <p>Reading Test Paper 1</p>
                            <p>Reading Test Paper 2</p>
                            <p>Speaking Test Paper 1</p>
                            <p>Speaking Test Paper 2</p>
                            <p>Completed Course Package</p>
                          </div>

                          <div>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={Styles.TableRow}>
                          <div>
                            <p>Reading Lesson 1</p>
                            <p>Reading Lesson 2</p>
                            <p>Writing Lesson 1</p>
                            <p>Writing Lesson 2</p>
                            <p>Writing Lesson 3</p>
                          </div>

                          <div>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={Styles.TableRow}>
                          <div>
                            <p>Writing Cheat Sheet 1</p>
                            <p>Writing Cheat Sheet 2</p>
                            <p>Reading Cheat Sheet 1</p>
                            <p>Reading Cheat Sheet 2</p>
                            <p>Speaking Cheat Sheet 1</p>
                            <p>Speaking Cheat Sheet 2</p>
                          </div>

                          <div>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={Styles.TableRow}>
                          <div>
                            <p>Mock Test 1</p>
                            <p>Mock Test 2</p>
                            <p>Mock Test 3</p>
                            <p>Mock Test 4</p>
                            <p>Mock Test 5</p>
                          </div>

                          <div>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>
                        <div className={Styles.TableTopSection}>
                          <p> TestPapers </p>
                          <p>
                            <PiVideo />
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className={Styles.TableRow}>
                          <div>
                            <p>Writing Test Paper 1</p>
                            <p>Writing Test Paper 2</p>
                            <p>Reading Test Paper 1</p>
                            <p>Reading Test Paper 2</p>
                            <p>Speaking Test Paper 1</p>
                            <p>Speaking Test Paper 2</p>
                            <p>Completed Course Package</p>
                          </div>

                          <div>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div className={Styles.TableTopSection}>
                          <p>Cheat Sheets </p>
                          <p>
                            <IoNewspaperOutline />
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className={Styles.TableRow}>
                          <div>
                            <p>Writing Cheat Sheet 1</p>
                            <p>Writing Cheat Sheet 2</p>
                            <p>Reading Cheat Sheet 1</p>
                            <p>Reading Cheat Sheet 2</p>
                            <p>Speaking Cheat Sheet 1</p>
                            <p>Speaking Cheat Sheet 2</p>
                          </div>

                          <div>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className={Styles.TableTopSection}>
                          <p>Lessons </p>
                          <p>
                            <HiOutlineClipboardCopy />
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      {" "}
                      <td>
                        <div className={Styles.TableRow}>
                          <div>
                            <p>Reading Lesson 1</p>
                            <p>Reading Lesson 2</p>
                            <p>Writing Lesson 1</p>
                            <p>Writing Lesson 2</p>
                            <p>Writing Lesson 3</p>
                          </div>

                          <div>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      {" "}
                      <td>
                        <div className={Styles.TableTopSection}>
                          <p> Mock Test </p>
                          <p>
                            <LuFileSpreadsheet />
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className={Styles.TableRow}>
                          <div>
                            <p>Mock Test 1</p>
                            <p>Mock Test 2</p>
                            <p>Mock Test 3</p>
                            <p>Mock Test 4</p>
                            <p>Mock Test 5</p>
                          </div>

                          <div>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                            <p>
                              <span>Mock Completed </span>
                              <span>
                                <IoCheckmarkDone />
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </div>
  );
};

export default AccessCourseMaterial;
