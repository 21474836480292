import { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Navbar2 from "../../Components/Navbar2";
import "./ContactUs.css";
import { ContactUs_Model } from "../../Model/HomeModel";
import { useDispatch, useSelector } from "react-redux";
import { Contact_Us } from "../../Action/ContactAction";
import { CONTACT_BOOKING_RESET } from "../../Constant/ContactConstant";
import Loading from "../../Components/Loading";
export default function ContactUs() {
  const [ContactUsForm, setContactUsForm] = useState(ContactUs_Model);
  const { loading, success, error } = useSelector((s) => s.Contact_Us_Reducer);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setContactUsForm({ ...ContactUsForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(Contact_Us({ ...ContactUsForm, link: window.location.href }));
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setContactUsForm(ContactUs_Model);
        dispatch({ type: CONTACT_BOOKING_RESET });
      }, 5000);
    }
  }, [success, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* navbar Section */}
      <Navbar2 />
      {loading ? <Loading /> : ""}
      <section className={"showcase"}>
        <img src={"/assets/image/Contact-Us.jpg"} alt="Banner" />
        <div className={"overlay"}>
          <div>
            <div>
              <p>
                <span> HOME</span> /<span> CONTACT US </span>{" "}
              </p>
            </div>
            <div>
              <h1 style={{ fontSize: "40px" }} className="Font_Rajdhani">
                Contact Us
              </h1>
            </div>
            <div>
              {/* <p>
                We help internationals to get enrolled into the best possible
                career-oriented courses <br /> along with the selection of
                colleges and universities across Australia.
              </p> */}
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us Form */}
      <section className="ContactUs">
        <div className="section-header">
          <div className="container">
            <h2>Contact Us</h2>
            <p>
              We are happy to assist you with inquiries, feedback, or support.
              If you have any questions, we're here to help.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="contact-info">
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-home"></i>
                </div>

                <div className="contact-info-content">
                  <h4>Address</h4>
                  <p>
                    115, 1st Floor,
                    <br /> 38 Ansal Tower, Nehru Place,
                    <br />
                    New Delhi-110019
                  </p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-phone"></i>
                </div>

                <div className="contact-info-content">
                  <h4>Phone</h4>
                  <p>+91 92891 92873</p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-envelope"></i>
                </div>

                <div className="contact-info-content">
                  <h4>Email</h4>
                  <p>info@testifyi.com</p>
                </div>
              </div>
            </div>

            <div className="contact-form">
              <form onSubmit={handleSubmit} id="contact-form">
                <h2>Send Message</h2>
                <div className={"input-box"}>
                  <input
                    type="text"
                    className={ContactUsForm?.First_Name ? " Active" : ""}
                    required="true"
                    name="First_Name"
                    value={ContactUsForm?.First_Name}
                    onChange={handleChange}
                  />
                  <span>First Name</span>
                </div>

                <div className="input-box">
                  <input
                    type="text"
                    required="true"
                    className={
                      ContactUsForm?.Last_Name ? " Active" : "InActive"
                    }
                    name="Last_Name"
                    value={ContactUsForm?.Last_Name}
                    onChange={handleChange}
                  />
                  <span>Last Name</span>
                </div>

                <div className="input-box">
                  <input
                    type="email"
                    required="true"
                    name="EmailAddress"
                    className={
                      ContactUsForm?.EmailAddress ? " Active" : "InActive"
                    }
                    value={ContactUsForm?.EmailAddress}
                    onChange={handleChange}
                  />
                  <span>Email</span>
                </div>

                <div className="input-box">
                  <input
                    type="number"
                    required="true"
                    name="PhoneNo"
                    className={ContactUsForm?.PhoneNo ? " Active" : "InActive"}
                    value={ContactUsForm?.PhoneNo}
                    onChange={handleChange}
                  />
                  <span>Phone No</span>
                </div>

                <div className="input-box">
                  <textarea
                    required="true"
                    name="Query"
                    className={ContactUsForm?.Query ? " Active" : "InActive"}
                    value={ContactUsForm?.Query}
                    onChange={handleChange}
                  ></textarea>
                  <span>Type your Message...</span>
                </div>

                <div id="message">
                  {error ? <p style={{ color: "red" }}>{error}</p> : ""}
                  {success ? <p style={{ color: "green" }}>{success}</p> : ""}
                </div>

                <div className="input-box">
                  <input
                    type="submit"
                    value={loading ? "Loading..." : "Send"}
                    disabled={loading}
                    name=""
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us Form */}

      {/* Top Footer Section*/}

      <section className={"Footer_Top"}>
        <div>
          <div>
            <h1 className={"Font_Rajdhani"}>
              Start Your IELTS Journey With The Best
            </h1>
          </div>
          <div>
            <button className={"Font_Rajdhani"}>Create Account</button>
            <button className={"Font_Rajdhani"}>Login Now</button>
          </div>
        </div>
      </section>
      {/* Footer Section */}
      <Footer />
    </>
  );
}
